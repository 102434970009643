import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Itinerary, ItineraryDetail } from '../../store/ducks/itineraries/types';
import * as ItinerariesActions from '../../store/ducks/itineraries/actions';
import { ApplicationState } from '../../store';

import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom'
//import {browserHistory} from 'react-router-dom'

import './styles.css'

interface StateProps {
    itineraries: Itinerary[]
}

interface DispatchProps {
    changeCurrentItinerary(itinerary: Itinerary): void,
    clearItinerarySearch(): void
}

interface OwnProps {

}

type Props = StateProps & DispatchProps & OwnProps

class ItineraryList extends Component<Props> {
    render() {

        const { itineraries } = this.props;
        return (
            <div className="itineraries-footer">
                <div className="itineraries-header-result">
                    <h2 className="itineraries-text-results">
                        {itineraries.length}{this.renderResultNumbers(itineraries)}
                    </h2>
                </div>
                <div className="itineraries-list-container">
                    {itineraries.map((it, index) => this.renderItinerary(it, index))}
                </div>
            </div>
        )
    }



    private renderItinerary(itinerary: Itinerary, index: any) {
        return (
            <div key={index} className="itineraries-card">
                {/* <div className="itineraries-journey-time">
                    <div className="itineraries-journey-time-title">
                        <FormattedMessage id="itineraries.tempoDeViagem" />
                    </div>
                    {itinerary.journeyTimeText}
                </div> */}
                {/* <div className="itineraries-journey-time">
                    <div className="itineraries-journey-time-title">
                        <FormattedMessage id="itineraries.tempoPrevisto" />
                    </div>
                    {itinerary.journeyTimeText}
                </div> */}
                <div className="itineraries-itinerary-description">


                    <div className="linkItinerary" >
                        <div className="itineraries-journey-time-title">
                            <FormattedMessage id="itineraries.trajeto" />
                        </div>
                        {this.renderItineraryDescription(itinerary.itinerary)}</div>

                    <Link to={`/maps/${index}`} data-index={index} onClick={this.onItinerarySelected}><FormattedMessage id="itineraries.ir" /></Link>

                </div>

            </div>
        )
    }

    onItinerarySelected = (e: any) => {
        var index = e.target.getAttribute("data-index")
        const { itineraries, changeCurrentItinerary } = this.props;
        changeCurrentItinerary(itineraries[index])
    }

    private renderItineraryDescription(itinerarydetail: ItineraryDetail[]) {

        const views: JSX.Element[] = [];
        itinerarydetail.map((item: any, row: any) =>
            (item.polyLine && item.polyLine.length > 0) ?
                views.push(<p key={row}>{item.name}</p>) : null

        );

        return views.length > 0 ? views[0] : views;
    }

    private renderResultNumbers(itineraries: Itinerary[]) {
        if (itineraries.length > 1)
            return <div className="resultados">
                <FormattedMessage id="itineraries.resultados" />
                <button className="buttonClose" onClick={this.onClearItinerary}></button>
            </div>;

        else
            return <FormattedMessage id="itineraries.resultado" />;
    }

    onClearItinerary = () => {
        this.props.clearItinerarySearch();
    }


}

const mapStateToProps = (state: ApplicationState) => ({
    itineraries: state.itineraries.itineraries,
});

const mapDispatchToProps = (dispatch: Dispatch) => (
    bindActionCreators(ItinerariesActions, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryList);