import axios from 'axios';

import { config,configClient } from '../config/config'

const api = axios.create({
    baseURL: config.API_ROTAS
})

const places = axios.create({
    baseURL: config.API_PLACES
})

const placesClient = (clienteId:string) => axios.create({
    baseURL: configClient[clienteId] !== undefined ? configClient[clienteId].API_PLACES :  configClient["0"].API_PLACES 
})

export { api, places, placesClient };
