import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Itinerary } from '../../store/ducks/itineraries/types';
import * as ItinerariesActions from '../../store/ducks/itineraries/actions';
import { ApplicationState } from '../../store';

import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom'
import './styles.css'

import Logo from "../../assets/logo-sonda.png"
import bgImage from "../../assets/bg-map-png.png"
import arrow from "../../assets/icons/icone-voltar.jpg"


import ItineraryMap from "../../components/ItineraryMap/"
import ItineraryDetails from "../../components/ItineraryDetails/"

import { LocalFromPlace } from '../../store/ducks/itineraries/types';


interface StateProps {
  itineraries: Itinerary[],
  clienteId: string,
  localOrigem: LocalFromPlace,
  localDestino: LocalFromPlace
}

interface DispatchProps {
  clearSearch(origin: LocalFromPlace, destiny: LocalFromPlace) : void
}

interface OwnProps {

}

type Props = StateProps & DispatchProps & OwnProps

class Map extends Component<Props> {

  componentDidMount() { }

  render() {
    const { clienteId } = this.props
    return (
      <div className="container-main">
        <div className="body-image">
          <img className="bgImg" src={bgImage} alt=" " />
        </div>
        <div className="container-header">
          <div className="topo-header no-padding">
            <img className="logo-img" src={Logo} alt="" />
            <div className="grid-title">
              <p className="header-title">
                {/* intl.formatMessage({id: "main.titulo"}) */}
                <FormattedMessage id="map.titulo" />
              </p>

              <Link to={`/${clienteId}`} className="itineraries-back" onClick={this.onItineraryBack}>
                <img className="arrow-img" src={arrow} alt="" />
              </Link>
            </div>
          </div>
        </div>


        {/* <Link to={`/`}><FormattedMessage id="map.link" /></Link> */}
        <ItineraryMap />
        <ItineraryDetails />
      </div>
    );
  }

  onItineraryBack = () => {
    this.props.clearSearch(this.props.localOrigem, this.props.localDestino);
  }

}
const mapStateToProps = (state: ApplicationState) => ({
  itineraries: state.itineraries.itineraries,
  clienteId: state.itineraries.clienteId,
  localOrigem: state.itineraries.localOrigem,
  localDestino: state.itineraries.localDestino
});

const mapDispatchToProps = (dispatch: Dispatch) => (
  bindActionCreators(ItinerariesActions, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Map);