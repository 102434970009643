import { Reducer } from 'redux';
import { ItinerariesState, ItinerariesTypes, SourcesTypes } from './types';

const INITIAL_STATE: ItinerariesState = {
  itineraries: [],
  error: false,
  loading: false,
  showProgress: false,
  localOrigem: {
    nome: "",
    descricao: "",
    latitude: "",
    longitude: ""
  },
  localDestino: {
    nome: "",
    descricao: "",
    latitude: "",
    longitude: ""
  },
  listaPlaces: [],
  sourceType: SourcesTypes.SOURCE_ORIGEM,
  clienteId: "",
  currentItinerary: {
    itinerary: [],
    journeyTimeText: "",
    start: {
      clientId: 0,
      id: 0,
      name: "",
      walk: ""
    },
    end: {
      clientId: 0,
      id: 0,
      name: "",
      walk: ""
    }
  },
  mapPolylines: [[0, 0]],
  mapPolylinesColor: 0
};

const reducer: Reducer<ItinerariesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ItinerariesTypes.LOAD_PLACES_SUCCESS:
      // console.log("reducer " + JSON.stringify(action))
      /* reducer {
        "type":"@itineraries/LOAD_PLACES_SUCCESS",
        "payload":{
          "data":[
            {"nome":"Teste localizacao 1 ","latitude":"22.222222","longitude":"55.5555"},
            {"nome":"Teste localizacao 2","latitude":"22.222222","longitude":"55.5555"}
          ],
            "source":"@sourcestypes/SOURCE_ORIGEM"}} */

      return {
        ...state, listaPlaces: action.payload.data, sourceType: action.payload.source, itineraries: []
      };
    case ItinerariesTypes.LOAD_PLACES_FAILURE:
      return {
        ...state, listaPlaces: [], itineraries: []
      };
    case ItinerariesTypes.CHANGE_ORIGIN_DESTINY:
      return {
        ...state, localOrigem: action.payload.origin, localDestino: action.payload.destiny, listaPlaces: [],
        showProgress: (action.payload.origin.nome && action.payload.destiny.nome) ? true : false
      };
    case ItinerariesTypes.REVERSE_ITINERARY_SEARCH:
      return {
        ...state, localDestino: action.payload.origin, localOrigem: action.payload.destiny,
        showProgress: (action.payload.origin.nome && action.payload.destiny.nome) ? true : false, itineraries: []
      }
    case ItinerariesTypes.LOAD_ITINERARIES_SUCCESS:
      return {
        ...state, itineraries: action.payload.itineraries, listaPlaces: [], showProgress: false
      }
    case ItinerariesTypes.CHANGE_CURRENT_CLIENTEID:
      return {
        ...state, clienteId: action.payload.clienteId
      }
    case ItinerariesTypes.CHANGE_CURRENT_ITINERARY:
      return {
        ...state, currentItinerary: action.payload.itinerary, mapPolylines: []
      }
    case ItinerariesTypes.SHOW_MAP_POLYLINES:
      return {
        ...state, mapPolylines: action.payload.mapPolylines, mapPolylinesColor: action.payload.mapPolylinesColor
      }
    case ItinerariesTypes.CLEAR_ITINERARY_SEARCH:
      return {
        ...state, itineraries: []
      }
    case ItinerariesTypes.CLEAR_SEARCH:
      return {
        ...state, localOrigem: action.payload.origin, localDestino: action.payload.destiny
      }

    default:
      return state;
  }
};

export default reducer;
