/**
 * Action types
 */

export enum ItinerariesTypes {
  LOAD_PLACES = '@itineraries/LOAD_PLACES',
  LOAD_PLACES_SUCCESS = '@itineraries/LOAD_PLACES_SUCCESS',
  LOAD_PLACES_FAILURE = '@itineraries/LOAD_PLACES_FAILURE',
  CHANGE_ORIGIN_DESTINY = '@itineraries/CHANGE_ORIGIN_DESTINY',
  LOAD_ITINERARIES_SUCCESS = '@itineraries/LOAD_ITINERARIES_SUCCESS',
  LOAD_ITINERARIES_FAILURE = '@itineraries/LOAD_ITINERARIES_FAILURE',
  CHANGE_CURRENT_ITINERARY = '@itineraries/CHANGE_CURRENT_ITINERARY',
  CHANGE_CURRENT_CLIENTEID = '@itineraries/CHANGE_CURRENT_CLIENTEID',
  SHOW_MAP_POLYLINES = '@itineraries/SHOW_MAP_POLYLINES',
  CLEAR_SEARCH = '@itineraries/CLEAR_SEARCH',
  CLEAR_ITINERARY_SEARCH = '@itineraries/CLEAR_ITINERARY_SEARCH',
  REVERSE_ITINERARY_SEARCH = '@itineraries/REVERSE_ITINERARY_SEARCH'
}

/**
 * Sources types
 */

export enum SourcesTypes {
  SOURCE_ORIGEM = '@sourcestypes/SOURCE_ORIGEM',
  SOURCE_DESTINO = '@sourcestypes/SOURCE_DESTINO',
}

/**
  * Data types
  */

export interface Itinerary {
  journeyTimeText: string
  itinerary: ItineraryDetail[]
  start: WalkDetail
  end: WalkDetail
}

export interface WalkDetail {
  id: number
  name: string
  clientId: number
  walk: string

}
export interface ItineraryDetail {
  id: number
  name: string
  clientId: number
  latitude: string
  longitude: string
  polyLine: LatLong[]
  way: number
}

export interface LatLong {
  lat: number
  lng: number
}

export interface LocalFromPlace {
  nome: string
  descricao: string
  latitude: string
  longitude: string
}


/**
  * State type
  */

export interface ItinerariesState {
  readonly itineraries: Itinerary[]
  readonly loading: boolean
  readonly error: boolean
  readonly localOrigem: LocalFromPlace
  readonly localDestino: LocalFromPlace
  readonly listaPlaces: LocalFromPlace[]
  readonly sourceType: SourcesTypes
  readonly clienteId: string
  readonly currentItinerary: Itinerary
  readonly mapPolylines: [number, number][]
  readonly mapPolylinesColor: number
  readonly showProgress: boolean
}

