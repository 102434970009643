const configs: any = {
    dev: {
        API_ROTAS: "http://10.99.2.243:8888/api/rotas",
        API_PLACES: "http://sso-admin.m2mcontrol.com.br/places/api/?q=",
    },

    qa: {
        API_ROTAS: "http://10.99.2.243:8888/api/rotas",
        API_PLACES: "http://sso-admin.m2mcontrol.com.br/places/api/?q=",
    },

    zn0: {
        API_ROTAS: "http://sondaroteirizacao.zn0.m2mcontrol.com.br/api/rotas/",
        API_PLACES: "http://sso-admin.m2mcontrol.com.br/places/api/?q=",
    },

    zn4: {
        API_ROTAS: "http://sondaroteirizacao.zn4.m2mcontrol.com.br/api/rotas/",
        API_PLACES: "http://sso-admin.m2mcontrol.com.br/places/api/?q=",
    },

    zn5: {
        API_ROTAS: "http://sondaroteirizacao.zn5.m2mcontrol.com.br/api/rotas/",
        API_PLACES: "http://sso-admin.m2mcontrol.com.br/places/api/?q=",
    },
};

const configClient: any = {
    209: {
        API_PLACES: "http://sso-admin.m2mcontrol.com.br/places/api/?zoom=16&q="
    },

    1307: {
        API_PLACES: "http://sso-admin.m2mcontrol.com.br/places/api/?lat=-23.701545&lon=-46.552904&zoom=16&q="
    },

    0: {
        API_PLACES: "http://sso-admin.m2mcontrol.com.br/places/api/?zoom=16&q="
    }
}

const config = configs[process.env.REACT_APP_STAGE || "dev"];

export {
    config, configClient
};

