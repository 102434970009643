import pt from "date-fns/locale/pt";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";

import messages_pt from "../translations/pt.json";
import messages_en from "../translations/en.json";
import messages_es from "../translations/es.json";

import { getLanguage } from '../services/localStorage';

export const LANGUAGES: any = {
  "pt-br": {
    locale: "pt",
    messages: messages_pt,
    calendar_locale: pt
  },
  "en-us": {
    locale: "en",
    messages: messages_en,
    calendar_locale: enUS
  },
  "es-es": {
    locale: "es",
    messages: messages_es,
    calendar_locale: es
  },
  default: {
    locale: "pt",
    messages: messages_pt,
    calendar_locale: pt
  }
};

export const getLocale = () => {
  const language = getLanguage();
  return LANGUAGES[language || "default"].locale;
}

export const getCalendarLocale = () => {
  const language = getLanguage();
  return LANGUAGES[language || "default"].calendar_locale;
}
