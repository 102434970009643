import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Main from './pages/main'
import Map from './pages/map'

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/:clienteId" component={Main} />
            <Route path="/maps/:id" component={Map} />
        </Switch>
    </BrowserRouter>
)

export default Routes;