import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { IntlProvider } from "react-intl";

import { LANGUAGES } from "./constants/languages";
import { initGetLocalStorage, getLanguage } from "./services/localStorage";

initGetLocalStorage();

const language: any = getLanguage() || "default";

let i18nConfig = {
    locale: LANGUAGES[language].locale,
    defaultLocale: LANGUAGES.default,
    messages: LANGUAGES[language].messages
};

ReactDOM.render(
    <IntlProvider
        locale={i18nConfig.locale}
        defaultLocale={i18nConfig.locale}
        messages={i18nConfig.messages}>
        <App />
    </IntlProvider>,
    document.getElementById("root")
);



