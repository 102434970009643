import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { LocalFromPlace, SourcesTypes } from '../../store/ducks/itineraries/types';
import * as ItinerariesActions from '../../store/ducks/itineraries/actions';
import { ApplicationState } from '../../store';
import { FormattedMessage } from "react-intl";
import './styles.css'
import Location from "../../assets/icons/icone-pin.svg"


interface StateProps {
    listaPlaces: LocalFromPlace[],
    sourceType: SourcesTypes,
    localOrigem: LocalFromPlace,
    localDestino: LocalFromPlace,
    clienteId: string
}

interface DispatchProps {
    changeFilter(origin: LocalFromPlace, destiny: LocalFromPlace, clienteId: string): void
}

interface OwnProps {

}

type Props = StateProps & DispatchProps & OwnProps

class PlacesList extends Component<Props> {

    handlePlace = (e: any) => {
        var index = e.target.getAttribute("data-index")
        //console.log(index);
        const { changeFilter, sourceType, listaPlaces, localOrigem, localDestino, clienteId } = this.props;

        switch (sourceType) {
            case SourcesTypes.SOURCE_ORIGEM:
                changeFilter(listaPlaces[index], localDestino, clienteId)
                break;
            case SourcesTypes.SOURCE_DESTINO:
                changeFilter(localOrigem, listaPlaces[index], clienteId)
                break;
        }

    };

    render() {
        const { listaPlaces } = this.props

        var listItems = listaPlaces.map((item, index) => {
            return (
                <p className="linePlaces" key={index} data-index={index} onClick={this.handlePlace}>
                    <img className="location-img" src={Location} alt=" " />
                    {item.nome + item.descricao}
                </p>
            )
        });
        return (
            <div className="modal-places">
                <div className="modal-places-title"><FormattedMessage id="places.titulo" /></div>
                <div className="modal-places-content">
                    <ul>
                        {listItems}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    listaPlaces: state.itineraries.listaPlaces,
    sourceType: state.itineraries.sourceType,
    localOrigem: state.itineraries.localOrigem,
    localDestino: state.itineraries.localDestino,
    clienteId: state.itineraries.clienteId
});

const mapDispatchToProps = (dispatch: Dispatch) => (
    bindActionCreators(ItinerariesActions, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(PlacesList);