import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as ItinerariesActions from '../../store/ducks/itineraries/actions';
import {
    Itinerary,/* , ItineraryDetail */
    ItineraryDetail
} from '../../store/ducks/itineraries/types';
import { ApplicationState } from '../../store';

import './styles.css'

import lastPoint from "../../assets/icons/icone-pin-point.svg"

import { FormattedMessage } from "react-intl";


interface StateProps {
    clienteId: string,
    itineraries: Itinerary[],
    currentItinerary: Itinerary,
    mapPolylines: [number, number][],
    mapPolylinesColor: number
}

interface DispatchProps {
    changeMapPolyline(mapPolylines: [number, number][] | undefined, mapPolylinesColor: number): void    
}

interface OwnProps {

}

type Props = StateProps & DispatchProps & OwnProps

class ItineraryDetails extends Component<Props> {

    state = {
        activeIndex: null
    }



    componentDidMount() {

        const { currentItinerary, changeMapPolyline } = this.props

        if (currentItinerary) {

            const filterFirst = currentItinerary.itinerary.find(c => c.polyLine && c.polyLine.length > 0)

            const polyline: [number, number][] | undefined = filterFirst?.polyLine.map(p => { return [p.lat, p.lng] })

            changeMapPolyline(polyline, 0);
        }
    }

    render() {
        /* const { currentItinerary } = this.props */
        return (
            <div className="container-footer">
             
                <div className="title-map">
                    <div></div>
                    <div><FormattedMessage id="itinerariesDetails.partidaMap" /></div>
                    {/*<div><FormattedMessage id="itinerariesDetails.previsaoChegadaMap" /></div>*/}
                    <div className="titleTempoEstimado"><FormattedMessage id="itinerariesDetails.tempoEstimado" /></div>
                </div>
                <div className="routers">
                    <div className="points">{this.renderItemTerminals()}</div>
                    <div className="result">{this.renderItemNames()}</div>
                </div>
            </div>
        )
    }



    private renderWalk(index: number) {
        const { walk } = index === 0 ? this.props.currentItinerary.start : this.props.currentItinerary.end
        const key = index === 0 ? 101 : 102

        return (
            <div key={key} className="results dot-text-walk">
                <div className="dot-text"><FormattedMessage id="details.caminhada" /></div>
                <div className=" dot-text">{walk}</div>
            </div>
        );
    }

    private renderItemNames() {
        const views: JSX.Element[] = []
        const intermediates = this.props.currentItinerary.itinerary.map((item: any, index: any) => {
            const random = Math.floor(Math.random() * 1000);
            return (<div className="results" key={index * random}>{this.renderItemName(item, index)}</div>);
        });

        if (this.props.currentItinerary.start.id)
            views.push(this.renderWalk(0))

        views.push(...intermediates);

        if (this.props.currentItinerary.end.id)
            views.push(this.renderWalk(1))

        return views;
    }

    private renderItemName(item: ItineraryDetail, index: number) {
        if (item.polyLine.length > 0) {
            return (
                <div className="line-button line-text-container">
                    <div
                        className={this.state.activeIndex === index ? "line-text-selected" : "line-text"}
                        data-index={index}
                        onClick={(event) => {
                            this.setState({ activeIndex: index });
                            this.handleClick(event);
                        }}>
                        {item.name}
                    </div>
                    <div><i className={this.state.activeIndex === index ? "arrow left" : " "}></i></div>
                </div>
            );
        } else {
            return (
                <div className="dot-text">{item.name}</div>
            );
        }
    }

    handleClick = (e: any) => {

        var index = e.target.getAttribute("data-index")
        const { currentItinerary, changeMapPolyline } = this.props;
        const current = currentItinerary.itinerary[index]

        const polyline: [number, number][] | undefined = current.polyLine.map(p => { return [p.lat, p.lng] })

        //const cor = (index % 2 === 0) ? 0 : 1

        //const cor = (index < 4) ? 0 : 1

        changeMapPolyline(polyline, 0);

    }

    private renderItemTerminals() {
        const views: JSX.Element[] = []
        const intermediates = this.props.currentItinerary.itinerary.map((item: any, index: any) => {
            //const random = Math.floor(Math.random() * 100);
            if (item.polyLine.length > 0) {
                return (
                    <div className="teste" key={index + 105}>
                        {this.renderLine(item)}
                    </div>
                );
            } else {
                return (
                    <div className="teste2" key={index * 105}>
                        {this.renderDot()}
                        {this.renderLine(item)}
                    </div>
                );
            }

        });


        if (this.props.currentItinerary.start.id) {

            views.push(
                <div className="firstPoint " key={103}>
                    {this.renderDot()}
                    <div className=" line" />
                </div>
            )
        }
        views.push(...intermediates);
        //views.push(this.renderDot());
        if (this.props.currentItinerary.end.id) {
            views.push(
            <div className="lastPoint" key={104}>
                    <div className=" line" />
                    {/*this.renderDot()*/}
                    <img className="locationImg" src={lastPoint} alt=" "/>
                </div>
            )
        }
        return views;
    }

    private renderDot() {
        return <div className="dot" />;
    }

    private renderLine(item: any) {
        const { currentItinerary } = this.props;
        const ultimo = currentItinerary.itinerary.length - 1
        /* console.log(JSON.stringify(ultimo))
        console.log(JSON.stringify(currentItinerary.itinerary[ultimo]))
        console.log(JSON.stringify(item)) */

        if (item.id !== currentItinerary.itinerary[ultimo].id) {
            return (
                <div className="line" />
            );
        }
        // return;
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    clienteId: state.itineraries.clienteId,
    itineraries: state.itineraries.itineraries,
    currentItinerary: state.itineraries.currentItinerary,
    mapPolylines: state.itineraries.mapPolylines,
    mapPolylinesColor: state.itineraries.mapPolylinesColor
});

const mapDispatchToProps = (dispatch: Dispatch) => (
    bindActionCreators(ItinerariesActions, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryDetails);