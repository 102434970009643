import { all, takeLatest } from 'redux-saga/effects';

import { ItinerariesTypes } from './itineraries/types';
import { loadPlaces, loadItineraries } from './itineraries/sagas';

export default function* rootSaga() {
  return yield all([
    takeLatest(ItinerariesTypes.LOAD_PLACES, loadPlaces),
    takeLatest(ItinerariesTypes.CHANGE_ORIGIN_DESTINY, loadItineraries),
    takeLatest(ItinerariesTypes.REVERSE_ITINERARY_SEARCH, loadItineraries)    
  ]);
}
