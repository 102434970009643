import { api, /* places, */ placesClient } from '../../../services/api';
import { call, put } from 'redux-saga/effects';

import { loadPlacesSuccess, loadPlacesFailure, loadItinerariesSuccess, loadItinerariesFailure } from './actions';
import { LocalFromPlace, Itinerary, ItinerariesTypes } from './types';

//metodos que vao interceptar action e chamar os reducers


export function* loadItineraries(action: any) {

  // yield console.log("saga loadItineraries" + JSON.stringify(action))

  if (action.payload.origin.nome && action.payload.destiny.nome && action.payload.clienteId) {
    //yield console.log("saga load itine ambos" + JSON.stringify(action))
    //var origem = action.payload.origin
    //var destino = action.payload.destiny

    if (action.type === ItinerariesTypes.REVERSE_ITINERARY_SEARCH){
      var temp = action.payload.origin
      action.payload.origin = action.payload.destiny
      action.payload.destiny = temp
    }

   // yield console.log("saga loadItineraries" + JSON.stringify(action.payload))
      var dados = {
        "origem": {
          "latitude": action.payload.origin.latitude,
          "longitude": action.payload.origin.longitude
        },
        "destino": {
          "latitude": action.payload.destiny.latitude,
          "longitude": action.payload.destiny.longitude
        },
        "clienteId": action.payload.clienteId
      }

    //yield console.log("dados == " + JSON.stringify(dados))

    /* const token = "aaaaaaaaaaaaaaaa"
    const headerParams = {
      Authorization: `JWT ${token}`
    }; */

    try {
      const response = yield call(api.post, '/', dados/* , { headers: headerParams } */);

      //yield console.log("response == ", response)

      if (response.status === 200) {

        const dataJson: any[] = response.data

        const dataItineraries = dataJson.map(json => ({
          start: {
            id: json.start.id,
            name: json.start.name,
            clientId: json.start.clientId,
            walk: json.start.walk
          },
          end: {
            id: json.end.id,
            name: json.end.name,
            clientId: json.end.clientId,
            walk: json.end.walk
          },
          journeyTimeText: json.journeyTimeText,
          itinerary: json.itinerary.map((it: { id: any; name: any; clientId: any; latitude: any; longitude: any; polyLine: any[]; way: any; }) => ({
            id: it.id,
            name: it.name,
            clientId: it.clientId,
            latitude: it.latitude ? it.latitude : " ",
            longitude: it.longitude ? it.longitude : " ",
            polyLine: it.polyLine ? it.polyLine.map((pl: { lat: any; lng: any; }) => ({
              lat: pl.lat,
              lng: pl.lng
            })) : [],
            way: it.way ? it.way : 0
          }))
        }) as Itinerary)

        //yield console.log("dataItineraries == ", dataItineraries)

        // const itineraries: Itinerary[] = [
        //   {
        //     start: {
        //       id: 10,
        //       name: "Caminhada",
        //       clientId: 209,
        //       walk: "Cerca de 9 min, 650 m"
        //     },
        //     itinerary: [
        //       {
        //         id: 4025,
        //         name: "Estação Rio 2",
        //         clientId: 209,
        //         latitude: "-22.973281",
        //         longitude: "-43.38278100000002",
        //         polyLine: [],
        //         way: 9432
        //       },
        //       {
        //         id: 3520,
        //         name: "53B - SULACAP X JD. OCEANICO (PARADOR)",
        //         clientId: 209,
        //         polyLine: [{
        //           lat: -22.88467454,
        //           lng: -43.39959319000002
        //         },
        //         {
        //           lat: -22.883855607097715,
        //           lng: -43.399509787559516
        //         },
        //         {
        //           lat: -22.883223002541275,
        //           lng: -43.39942395687103
        //         },
        //         {
        //           lat: -22.883143926764436,
        //           lng: -43.39946150779724
        //         },
        //         {
        //           lat: -22.883099446619713,
        //           lng: -43.399515151977546
        //         },
        //         {
        //           lat: -22.882946237120805,
        //           lng: -43.400137424468994
        //         },
        //         {
        //           lat: -22.8829511793654,
        //           lng: -43.40022325515748
        //         },
        //         {
        //           lat: -22.88298,
        //           lng: -43.40031
        //         },
        //         {
        //           lat: -22.88495,
        //           lng: -43.40067
        //         },
        //         {
        //           lat: -22.88552,
        //           lng: -43.40077
        //         },
        //         {
        //           lat: -22.88631,
        //           lng: -43.40091
        //         },
        //         {
        //           lat: -22.88683,
        //           lng: -43.40101
        //         },
        //         {
        //           lat: -22.8872,
        //           lng: -43.40108
        //         },
        //         {
        //           lat: -22.88743,
        //           lng: -43.4011
        //         },
        //         {
        //           lat: -22.88767,
        //           lng: -43.40109
        //         },
        //         {
        //           lat: -22.88795,
        //           lng: -43.40107
        //         },
        //         {
        //           lat: -22.88921,
        //           lng: -43.40095
        //         },
        //         {
        //           lat: -22.8904,
        //           lng: -43.40083
        //         },
        //         {
        //           lat: -22.89107,
        //           lng: -43.40072
        //         },
        //         {
        //           lat: -22.89165,
        //           lng: -43.40055
        //         },
        //         {
        //           lat: -22.89247,
        //           lng: -43.40028
        //         },
        //         {
        //           lat: -22.89335,
        //           lng: -43.39999
        //         },
        //         {
        //           lat: -22.89403,
        //           lng: -43.39986
        //         },
        //         {
        //           lat: -22.89512,
        //           lng: -43.39979
        //         },
        //         {
        //           lat: -22.89544,
        //           lng: -43.39972
        //         },
        //         {
        //           lat: -22.89591,
        //           lng: -43.39952
        //         },
        //         {
        //           lat: -22.896,
        //           lng: -43.39948
        //         },
        //         {
        //           lat: -22.89608,
        //           lng: -43.39943
        //         },
        //         {
        //           lat: -22.8967,
        //           lng: -43.39908
        //         },
        //         {
        //           lat: -22.89711,
        //           lng: -43.39879
        //         },
        //         {
        //           lat: -22.89746,
        //           lng: -43.39848
        //         },
        //         {
        //           lat: -22.89755,
        //           lng: -43.39842
        //         },
        //         {
        //           lat: -22.89787,
        //           lng: -43.3982
        //         },
        //         {
        //           lat: -22.89822,
        //           lng: -43.39802
        //         },
        //         {
        //           lat: -22.89868,
        //           lng: -43.39786
        //         },
        //         {
        //           lat: -22.89884,
        //           lng: -43.39781
        //         },
        //         {
        //           lat: -22.89894,
        //           lng: -43.39779
        //         },
        //         {
        //           lat: -22.89898,
        //           lng: -43.39778
        //         },
        //         {
        //           lat: -22.89906,
        //           lng: -43.39778
        //         },
        //         {
        //           lat: -22.89916,
        //           lng: -43.39779
        //         },
        //         {
        //           lat: -22.89928,
        //           lng: -43.39782
        //         },
        //         {
        //           lat: -22.89938,
        //           lng: -43.39785
        //         },
        //         {
        //           lat: -22.8995,
        //           lng: -43.39788
        //         },
        //         {
        //           lat: -22.89959,
        //           lng: -43.3979
        //         },
        //         {
        //           lat: -22.89969,
        //           lng: -43.39793
        //         },
        //         {
        //           lat: -22.89981,
        //           lng: -43.39796
        //         },
        //         {
        //           lat: -22.89992,
        //           lng: -43.398
        //         },
        //         {
        //           lat: -22.90003,
        //           lng: -43.39803
        //         },
        //         {
        //           lat: -22.91085,
        //           lng: -43.40118
        //         },
        //         {
        //           lat: -22.91114,
        //           lng: -43.40124
        //         },
        //         {
        //           lat: -22.91139,
        //           lng: -43.40126
        //         },
        //         {
        //           lat: -22.9117,
        //           lng: -43.40124
        //         },
        //         {
        //           lat: -22.91202,
        //           lng: -43.40118
        //         },
        //         {
        //           lat: -22.91231,
        //           lng: -43.40109
        //         },
        //         {
        //           lat: -22.9126,
        //           lng: -43.40097
        //         },
        //         {
        //           lat: -22.91286,
        //           lng: -43.40081
        //         },
        //         {
        //           lat: -22.91306,
        //           lng: -43.40066
        //         },
        //         {
        //           lat: -22.91336,
        //           lng: -43.40041
        //         },
        //         {
        //           lat: -22.91468,
        //           lng: -43.39918
        //         },
        //         {
        //           lat: -22.91488,
        //           lng: -43.39896
        //         },
        //         {
        //           lat: -22.91514,
        //           lng: -43.3987
        //         },
        //         {
        //           lat: -22.91579,
        //           lng: -43.3981
        //         },
        //         {
        //           lat: -22.91623,
        //           lng: -43.39769
        //         },
        //         {
        //           lat: -22.91647,
        //           lng: -43.39749
        //         },
        //         {
        //           lat: -22.91672,
        //           lng: -43.39731
        //         },
        //         {
        //           lat: -22.91697,
        //           lng: -43.39715
        //         },
        //         {
        //           lat: -22.91715,
        //           lng: -43.39705
        //         },
        //         {
        //           lat: -22.91744,
        //           lng: -43.39691
        //         },
        //         {
        //           lat: -22.91768,
        //           lng: -43.39682
        //         },
        //         {
        //           lat: -22.9179,
        //           lng: -43.39674
        //         },
        //         {
        //           lat: -22.91818,
        //           lng: -43.39667
        //         },
        //         {
        //           lat: -22.91844,
        //           lng: -43.39663
        //         },
        //         {
        //           lat: -22.91871,
        //           lng: -43.39662
        //         },
        //         {
        //           lat: -22.91911,
        //           lng: -43.39661
        //         },
        //         {
        //           lat: -22.91946,
        //           lng: -43.39663
        //         },
        //         {
        //           lat: -22.91982,
        //           lng: -43.39669
        //         },
        //         {
        //           lat: -22.92026,
        //           lng: -43.39681
        //         },
        //         {
        //           lat: -22.92062,
        //           lng: -43.39694
        //         },
        //         {
        //           lat: -22.92105,
        //           lng: -43.39713
        //         },
        //         {
        //           lat: -22.92225,
        //           lng: -43.39765
        //         },
        //         {
        //           lat: -22.92282,
        //           lng: -43.39787
        //         },
        //         {
        //           lat: -22.92317,
        //           lng: -43.39796
        //         },
        //         {
        //           lat: -22.92347,
        //           lng: -43.39801
        //         },
        //         {
        //           lat: -22.92374,
        //           lng: -43.39802
        //         },
        //         {
        //           lat: -22.92398,
        //           lng: -43.39801
        //         },
        //         {
        //           lat: -22.92422,
        //           lng: -43.39799
        //         },
        //         {
        //           lat: -22.92446,
        //           lng: -43.39793
        //         },
        //         {
        //           lat: -22.92468,
        //           lng: -43.39784
        //         },
        //         {
        //           lat: -22.92485,
        //           lng: -43.39776
        //         },
        //         {
        //           lat: -22.92516,
        //           lng: -43.39759
        //         },
        //         {
        //           lat: -22.92528,
        //           lng: -43.39753
        //         },
        //         {
        //           lat: -22.9255,
        //           lng: -43.3974
        //         },
        //         {
        //           lat: -22.92701,
        //           lng: -43.39658
        //         },
        //         {
        //           lat: -22.92747,
        //           lng: -43.39636
        //         },
        //         {
        //           lat: -22.92828,
        //           lng: -43.39594
        //         },
        //         {
        //           lat: -22.92856,
        //           lng: -43.39577
        //         },
        //         {
        //           lat: -22.92877,
        //           lng: -43.39565
        //         },
        //         {
        //           lat: -22.92902,
        //           lng: -43.39553
        //         },
        //         {
        //           lat: -22.92931,
        //           lng: -43.39542
        //         },
        //         {
        //           lat: -22.92972,
        //           lng: -43.39531
        //         },
        //         {
        //           lat: -22.93008,
        //           lng: -43.39524
        //         },
        //         {
        //           lat: -22.93055,
        //           lng: -43.39519
        //         },
        //         {
        //           lat: -22.93083,
        //           lng: -43.39521
        //         },
        //         {
        //           lat: -22.9312,
        //           lng: -43.39526
        //         },
        //         {
        //           lat: -22.9315,
        //           lng: -43.39532
        //         },
        //         {
        //           lat: -22.93354,
        //           lng: -43.39569
        //         },
        //         {
        //           lat: -22.93392,
        //           lng: -43.39575
        //         },
        //         {
        //           lat: -22.93441,
        //           lng: -43.39578
        //         },
        //         {
        //           lat: -22.93481,
        //           lng: -43.39577
        //         },
        //         {
        //           lat: -22.93519,
        //           lng: -43.39572
        //         },
        //         {
        //           lat: -22.93581,
        //           lng: -43.3956
        //         },
        //         {
        //           lat: -22.93694,
        //           lng: -43.39538
        //         },
        //         {
        //           lat: -22.93778,
        //           lng: -43.3952
        //         },
        //         {
        //           lat: -22.93805,
        //           lng: -43.39515
        //         },
        //         {
        //           lat: -22.93918,
        //           lng: -43.39498
        //         },
        //         {
        //           lat: -22.94004,
        //           lng: -43.39492
        //         },
        //         {
        //           lat: -22.94132,
        //           lng: -43.39475
        //         },
        //         {
        //           lat: -22.94224,
        //           lng: -43.39468
        //         },
        //         {
        //           lat: -22.94242,
        //           lng: -43.39466
        //         },
        //         {
        //           lat: -22.94332,
        //           lng: -43.39455
        //         },
        //         {
        //           lat: -22.94441,
        //           lng: -43.3944
        //         },
        //         {
        //           lat: -22.94453,
        //           lng: -43.39439
        //         },
        //         {
        //           lat: -22.94512,
        //           lng: -43.39431
        //         },
        //         {
        //           lat: -22.94558,
        //           lng: -43.39418
        //         },
        //         {
        //           lat: -22.94598,
        //           lng: -43.39402
        //         },
        //         {
        //           lat: -22.94637,
        //           lng: -43.3938
        //         },
        //         {
        //           lat: -22.94665,
        //           lng: -43.39358
        //         },
        //         {
        //           lat: -22.94698,
        //           lng: -43.39327
        //         },
        //         {
        //           lat: -22.94721,
        //           lng: -43.39301
        //         },
        //         {
        //           lat: -22.94741,
        //           lng: -43.39269
        //         },
        //         {
        //           lat: -22.94809,
        //           lng: -43.39153
        //         },
        //         {
        //           lat: -22.94841,
        //           lng: -43.39102
        //         },
        //         {
        //           lat: -22.94866,
        //           lng: -43.39071
        //         },
        //         {
        //           lat: -22.94888,
        //           lng: -43.39049
        //         },
        //         {
        //           lat: -22.94901,
        //           lng: -43.3904
        //         },
        //         {
        //           lat: -22.94919,
        //           lng: -43.39031
        //         },
        //         {
        //           lat: -22.94947,
        //           lng: -43.39019
        //         },
        //         {
        //           lat: -22.94978,
        //           lng: -43.39011
        //         },
        //         {
        //           lat: -22.95094,
        //           lng: -43.38987
        //         },
        //         {
        //           lat: -22.9512,
        //           lng: -43.38985
        //         },
        //         {
        //           lat: -22.95149,
        //           lng: -43.38987
        //         },
        //         {
        //           lat: -22.95174,
        //           lng: -43.38994
        //         },
        //         {
        //           lat: -22.95196,
        //           lng: -43.39003
        //         },
        //         {
        //           lat: -22.95217,
        //           lng: -43.39015
        //         },
        //         {
        //           lat: -22.95238,
        //           lng: -43.39027
        //         },
        //         {
        //           lat: -22.95348,
        //           lng: -43.39102
        //         },
        //         {
        //           lat: -22.95444,
        //           lng: -43.39173
        //         },
        //         {
        //           lat: -22.9551,
        //           lng: -43.39232
        //         },
        //         {
        //           lat: -22.9557,
        //           lng: -43.39291
        //         },
        //         {
        //           lat: -22.95603,
        //           lng: -43.39315
        //         },
        //         {
        //           lat: -22.95624,
        //           lng: -43.39326
        //         },
        //         {
        //           lat: -22.95641,
        //           lng: -43.39335
        //         },
        //         {
        //           lat: -22.95669,
        //           lng: -43.39343
        //         },
        //         {
        //           lat: -22.95682,
        //           lng: -43.39346
        //         },
        //         {
        //           lat: -22.95703,
        //           lng: -43.39348
        //         },
        //         {
        //           lat: -22.95723,
        //           lng: -43.39349
        //         },
        //         {
        //           lat: -22.95742,
        //           lng: -43.39347
        //         },
        //         {
        //           lat: -22.95754,
        //           lng: -43.39345
        //         },
        //         {
        //           lat: -22.95767,
        //           lng: -43.39343
        //         },
        //         {
        //           lat: -22.9579,
        //           lng: -43.39336
        //         },
        //         {
        //           lat: -22.95806,
        //           lng: -43.3933
        //         },
        //         {
        //           lat: -22.95821,
        //           lng: -43.39322
        //         },
        //         {
        //           lat: -22.95854,
        //           lng: -43.393
        //         },
        //         {
        //           lat: -22.95977,
        //           lng: -43.39206
        //         },
        //         {
        //           lat: -22.95995,
        //           lng: -43.39198
        //         },
        //         {
        //           lat: -22.96016,
        //           lng: -43.39192
        //         },
        //         {
        //           lat: -22.96031,
        //           lng: -43.3919
        //         },
        //         {
        //           lat: -22.96047,
        //           lng: -43.39189
        //         },
        //         {
        //           lat: -22.96063,
        //           lng: -43.3919
        //         },
        //         {
        //           lat: -22.96082,
        //           lng: -43.39193
        //         },
        //         {
        //           lat: -22.96106,
        //           lng: -43.39202
        //         },
        //         {
        //           lat: -22.9613,
        //           lng: -43.39215
        //         },
        //         {
        //           lat: -22.9613,
        //           lng: -43.39215
        //         },
        //         {
        //           lat: -22.96145,
        //           lng: -43.39228
        //         },
        //         {
        //           lat: -22.9636,
        //           lng: -43.39432
        //         },
        //         {
        //           lat: -22.96363,
        //           lng: -43.39434
        //         },
        //         {
        //           lat: -22.96402,
        //           lng: -43.39469
        //         },
        //         {
        //           lat: -22.96423,
        //           lng: -43.39488
        //         },
        //         {
        //           lat: -22.96486,
        //           lng: -43.39552
        //         },
        //         {
        //           lat: -22.96588,
        //           lng: -43.39645
        //         },
        //         {
        //           lat: -22.96606,
        //           lng: -43.39661
        //         },
        //         {
        //           lat: -22.96619,
        //           lng: -43.39674
        //         },
        //         {
        //           lat: -22.96713,
        //           lng: -43.39764
        //         },
        //         {
        //           lat: -22.96718,
        //           lng: -43.39768
        //         },
        //         {
        //           lat: -22.96723,
        //           lng: -43.39773
        //         },
        //         {
        //           lat: -22.96895,
        //           lng: -43.39932
        //         },
        //         {
        //           lat: -22.9695,
        //           lng: -43.39982
        //         },
        //         {
        //           lat: -22.96986,
        //           lng: -43.40011
        //         },
        //         {
        //           lat: -22.97014,
        //           lng: -43.40036
        //         },
        //         {
        //           lat: -22.97014,
        //           lng: -43.40036
        //         },
        //         {
        //           lat: -22.97054,
        //           lng: -43.40087
        //         },
        //         {
        //           lat: -22.97071,
        //           lng: -43.40101
        //         },
        //         {
        //           lat: -22.97235,
        //           lng: -43.40255
        //         },
        //         {
        //           lat: -22.97244,
        //           lng: -43.40264
        //         },
        //         {
        //           lat: -22.97248,
        //           lng: -43.4027
        //         },
        //         {
        //           lat: -22.97255,
        //           lng: -43.40273
        //         },
        //         {
        //           lat: -22.97261,
        //           lng: -43.40274
        //         },
        //         {
        //           lat: -22.97268,
        //           lng: -43.40274
        //         },
        //         {
        //           lat: -22.97274,
        //           lng: -43.40271
        //         },
        //         {
        //           lat: -22.9728,
        //           lng: -43.40267
        //         },
        //         {
        //           lat: -22.97284,
        //           lng: -43.40261
        //         },
        //         {
        //           lat: -22.97287,
        //           lng: -43.40255
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.40248
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.40248
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.4024
        //         },
        //         {
        //           lat: -22.97289,
        //           lng: -43.40164
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.40116
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.40085
        //         },
        //         {
        //           lat: -22.97289,
        //           lng: -43.40079
        //         },
        //         {
        //           lat: -22.9729,
        //           lng: -43.40062
        //         },
        //         {
        //           lat: -22.97292,
        //           lng: -43.40045
        //         },
        //         {
        //           lat: -22.97299,
        //           lng: -43.40012
        //         },
        //         {
        //           lat: -22.97306,
        //           lng: -43.39988
        //         },
        //         {
        //           lat: -22.97321,
        //           lng: -43.39921
        //         },
        //         {
        //           lat: -22.97323,
        //           lng: -43.39905
        //         },
        //         {
        //           lat: -22.97325,
        //           lng: -43.39885
        //         },
        //         {
        //           lat: -22.97326,
        //           lng: -43.39861
        //         },
        //         {
        //           lat: -22.97326,
        //           lng: -43.39846
        //         },
        //         {
        //           lat: -22.97326,
        //           lng: -43.39833
        //         },
        //         {
        //           lat: -22.97326,
        //           lng: -43.39823
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.39411
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.39294
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.39289
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.39278
        //         },
        //         {
        //           lat: -22.97333,
        //           lng: -43.39196
        //         },
        //         {
        //           lat: -22.97336,
        //           lng: -43.38823
        //         },
        //         {
        //           lat: -22.97337,
        //           lng: -43.38801
        //         },
        //         {
        //           lat: -22.97336,
        //           lng: -43.38778
        //         },
        //         {
        //           lat: -22.97336,
        //           lng: -43.38766
        //         },
        //         {
        //           lat: -22.97333,
        //           lng: -43.3874
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.3864
        //         },
        //         {
        //           lat: -22.97329,
        //           lng: -43.38574
        //         },
        //         {
        //           lat: -22.97329,
        //           lng: -43.38558
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.38367
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.38362
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.38202
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.38197
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.38131
        //         },
        //         {
        //           lat: -22.97331,
        //           lng: -43.37968
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.37956
        //         },
        //         {
        //           lat: -22.97331,
        //           lng: -43.37915
        //         },
        //         {
        //           lat: -22.97331,
        //           lng: -43.37885
        //         },
        //         {
        //           lat: -22.97331,
        //           lng: -43.37882
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.37872
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.37848
        //         },
        //         {
        //           lat: -22.97336,
        //           lng: -43.37789
        //         },
        //         {
        //           lat: -22.97338,
        //           lng: -43.37752
        //         },
        //         {
        //           lat: -22.97339,
        //           lng: -43.3773
        //         },
        //         {
        //           lat: -22.9734,
        //           lng: -43.37718
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37665
        //         },
        //         {
        //           lat: -22.97342,
        //           lng: -43.37602
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37565
        //         },
        //         {
        //           lat: -22.9734,
        //           lng: -43.37371
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37169
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37152
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37134
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37124
        //         },
        //         {
        //           lat: -22.97344,
        //           lng: -43.36988
        //         },
        //         {
        //           lat: -22.97344,
        //           lng: -43.36869
        //         },
        //         {
        //           lat: -22.97347,
        //           lng: -43.36632
        //         },
        //         {
        //           lat: -22.97347,
        //           lng: -43.36621
        //         },
        //         {
        //           lat: -22.97347,
        //           lng: -43.3661
        //         },
        //         {
        //           lat: -22.9734381786719,
        //           lng: -43.364823460578926
        //         },
        //         {
        //           lat: -22.97338878922277,
        //           lng: -43.36450159549714
        //         },
        //         {
        //           lat: -22.97338878922277,
        //           lng: -43.36415827274323
        //         },
        //         {
        //           lat: -22.97348756810297,
        //           lng: -43.36397588253022
        //         },
        //         {
        //           lat: -22.97360857213282,
        //           lng: -43.3638
        //         },
        //         {
        //           lat: -22.973759209651263,
        //           lng: -43.363710343837745
        //         },
        //         {
        //           lat: -22.973870335581832,
        //           lng: -43.363675475120544
        //         },
        //         {
        //           lat: -22.97398886980708,
        //           lng: -43.363659381866455
        //         },
        //         {
        //           lat: -22.974117281767096,
        //           lng: -43.363678157329566
        //         },
        //         {
        //           lat: -22.9742333463183,
        //           lng: -43.36373180150986
        //         },
        //         {
        //           lat: -22.974765132010546,
        //           lng: -43.36397675347377
        //         },
        //         {
        //           lat: -22.975106850256584,
        //           lng: -43.36420456460275
        //         },
        //         {
        //           lat: -22.97679168004786,
        //           lng: -43.364914655685425
        //         },
        //         {
        //           lat: -22.97837703243498,
        //           lng: -43.36560130119324
        //         },
        //         {
        //           lat: -22.978707929185077,
        //           lng: -43.3656656742096
        //         },
        //         {
        //           lat: -22.979312924879192,
        //           lng: -43.36566030979157
        //         },
        //         {
        //           lat: -22.980006814564575,
        //           lng: -43.36560000000001
        //         },
        //         {
        //           lat: -22.980727863415797,
        //           lng: -43.36560000000001
        //         },
        //         {
        //           lat: -22.981231607590193,
        //           lng: -43.36579442024231
        //         },
        //         {
        //           lat: -22.981656331611173,
        //           lng: -43.36579442024231
        //         },
        //         {
        //           lat: -22.983049021940193,
        //           lng: -43.365831971168525
        //         },
        //         {
        //           lat: -22.98491579709432,
        //           lng: -43.365853428840644
        //         },
        //         {
        //           lat: -22.98665,
        //           lng: -43.36594
        //         },
        //         {
        //           lat: -22.98665,
        //           lng: -43.36594
        //         },
        //         {
        //           lat: -22.98817,
        //           lng: -43.36597
        //         },
        //         {
        //           lat: -22.99203,
        //           lng: -43.36603
        //         },
        //         {
        //           lat: -22.996012205747157,
        //           lng: -43.36601972579957
        //         },
        //         {
        //           lat: -22.996397378456717,
        //           lng: -43.365944623947144
        //         },
        //         {
        //           lat: -22.99669860250231,
        //           lng: -43.36590707302094
        //         },
        //         {
        //           lat: -22.99692575461676,
        //           lng: -43.36601436138154
        //         },
        //         {
        //           lat: -22.99718253480763,
        //           lng: -43.36609482765198
        //         },
        //         {
        //           lat: -22.997370181561315,
        //           lng: -43.36621820926667
        //         },
        //         {
        //           lat: -22.99756276611626,
        //           lng: -43.3664220571518
        //         },
        //         {
        //           lat: -22.997809668989916,
        //           lng: -43.3667278289795
        //         },
        //         {
        //           lat: -22.998130642050562,
        //           lng: -43.367108702659614
        //         },
        //         {
        //           lat: -22.999207130743418,
        //           lng: -43.368310332298286
        //         },
        //         {
        //           lat: -22.99959723232488,
        //           lng: -43.36877703666688
        //         },
        //         {
        //           lat: -22.999789813702602,
        //           lng: -43.368889689445496
        //         },
        //         {
        //           lat: -23.00005646438738,
        //           lng: -43.36893796920777
        //         },
        //         {
        //           lat: -23.00027373492626,
        //           lng: -43.3689433336258
        //         },
        //         {
        //           lat: -23.0005058189792,
        //           lng: -43.368921875953674
        //         },
        //         {
        //           lat: -23.000644081629556,
        //           lng: -43.368814587593086
        //         },
        //         {
        //           lat: -23.000782344138287,
        //           lng: -43.36866974830628
        //         },
        //         {
        //           lat: -23.00100388008746,
        //           lng: -43.368436298649854
        //         },
        //         {
        //           lat: -23.001209475636742,
        //           lng: -43.36825400590897
        //         },
        //         {
        //           lat: -23.00140699263564,
        //           lng: -43.36805552244187
        //         },
        //         {
        //           lat: -23.001431682240177,
        //           lng: -43.3679160475731
        //         },
        //         {
        //           lat: -23.001424275359284,
        //           lng: -43.367696106433876
        //         },
        //         {
        //           lat: -23.001369958220366,
        //           lng: -43.364828824996955
        //         },
        //         {
        //           lat: -23.001360082374575,
        //           lng: -43.36427628993989
        //         },
        //         {
        //           lat: -23.00127119972996,
        //           lng: -43.36421191692352
        //         },
        //         {
        //           lat: -23.00116256530704,
        //           lng: -43.364195823669434
        //         },
        //         {
        //           lat: -23.001,
        //           lng: -43.3642
        //         },
        //         {
        //           lat: -23.00097075462114,
        //           lng: -43.36431698154611
        //         },
        //         {
        //           lat: -23.001004551444908,
        //           lng: -43.3661699295044
        //         },
        //         {
        //           lat: -23.00105393079669,
        //           lng: -43.3681333065033
        //         },
        //         {
        //           lat: -23.000895916807377,
        //           lng: -43.368186950683594
        //         },
        //         {
        //           lat: -23.000742840578823,
        //           lng: -43.368106484413154
        //         },
        //         {
        //           lat: -23.000713212901637,
        //           lng: -43.36764514446259
        //         },
        //         {
        //           lat: -23.000713212901637,
        //           lng: -43.367114067077644
        //         },
        //         {
        //           lat: -23.00089999999999,
        //           lng: -43.36698532104492
        //         },
        //         {
        //           lat: -23.000960110012855,
        //           lng: -43.366845846176155
        //         },
        //         {
        //           lat: -23.00089999999999,
        //           lng: -43.36673319339753
        //         },
        //         {
        //           lat: -23.00075271646978,
        //           lng: -43.366658091545105
        //         },
        //         {
        //           lat: -23.000540384655068,
        //           lng: -43.3667814731598
        //         },
        //         {
        //           lat: -23.000288548813895,
        //           lng: -43.36677879095078
        //         },
        //         {
        //           lat: -23.000204603429093,
        //           lng: -43.36669564247132
        //         },
        //         {
        //           lat: -23.000189789532246,
        //           lng: -43.36661249399186
        //         },
        //         {
        //           lat: -23.000219417324328,
        //           lng: -43.36544573307037
        //         },
        //         {
        //           lat: -23.000174975633758,
        //           lng: -43.36464643478394
        //         },
        //         {
        //           lat: -23.00011572002358,
        //           lng: -43.36412608623505
        //         },
        //         {
        //           lat: -23.00009103017832,
        //           lng: -43.36373984813691
        //         },
        //         {
        //           lat: -23.000095968147743,
        //           lng: -43.36350381374359
        //         },
        //         {
        //           lat: -23.000095968147743,
        //           lng: -43.36299419403076
        //         },
        //         {
        //           lat: -23.000086092208736,
        //           lng: -43.36261868476868
        //         },
        //         {
        //           lat: -23.00014,
        //           lng: -43.36126
        //         },
        //         {
        //           lat: -23.00013,
        //           lng: -43.3609
        //         },
        //         {
        //           lat: -23.00013,
        //           lng: -43.36034
        //         },
        //         {
        //           lat: -23.00013,
        //           lng: -43.35817
        //         },
        //         {
        //           lat: -23.00016,
        //           lng: -43.35545
        //         },
        //         {
        //           lat: -23.00016,
        //           lng: -43.35525
        //         },
        //         {
        //           lat: -23.00017,
        //           lng: -43.35486
        //         },
        //         {
        //           lat: -23.00017,
        //           lng: -43.35477
        //         },
        //         {
        //           lat: -23.00017,
        //           lng: -43.35461
        //         },
        //         {
        //           lat: -23.00019,
        //           lng: -43.35381
        //         },
        //         {
        //           lat: -23.00019,
        //           lng: -43.35362
        //         },
        //         {
        //           lat: -23.00019,
        //           lng: -43.35335
        //         },
        //         {
        //           lat: -23.00019,
        //           lng: -43.35329
        //         },
        //         {
        //           lat: -23.0002,
        //           lng: -43.35311
        //         },
        //         {
        //           lat: -23.00028,
        //           lng: -43.34903
        //         },
        //         {
        //           lat: -23.00029,
        //           lng: -43.3474
        //         },
        //         {
        //           lat: -23.00029,
        //           lng: -43.34734
        //         },
        //         {
        //           lat: -23.00029,
        //           lng: -43.34724
        //         },
        //         {
        //           lat: -23.00031,
        //           lng: -43.34666
        //         },
        //         {
        //           lat: -23.00037,
        //           lng: -43.34291
        //         },
        //         {
        //           lat: -23.00037,
        //           lng: -43.34274
        //         },
        //         {
        //           lat: -23.00037,
        //           lng: -43.3426
        //         },
        //         {
        //           lat: -23.00038,
        //           lng: -43.3415
        //         },
        //         {
        //           lat: -23.00038,
        //           lng: -43.34145
        //         },
        //         {
        //           lat: -23.00038,
        //           lng: -43.3413
        //         },
        //         {
        //           lat: -23.00043,
        //           lng: -43.33949
        //         },
        //         {
        //           lat: -23.00044,
        //           lng: -43.33933
        //         },
        //         {
        //           lat: -23.00045,
        //           lng: -43.33912
        //         },
        //         {
        //           lat: -23.00046,
        //           lng: -43.33895
        //         },
        //         {
        //           lat: -23.0006,
        //           lng: -43.33674
        //         },
        //         {
        //           lat: -23.0006,
        //           lng: -43.33668
        //         },
        //         {
        //           lat: -23.00061,
        //           lng: -43.33655
        //         },
        //         {
        //           lat: -23.00073,
        //           lng: -43.33463
        //         },
        //         {
        //           lat: -23.00074,
        //           lng: -43.33456
        //         },
        //         {
        //           lat: -23.00075,
        //           lng: -43.33441
        //         },
        //         {
        //           lat: -23.00089,
        //           lng: -43.33233
        //         },
        //         {
        //           lat: -23.00095,
        //           lng: -43.33127
        //         },
        //         {
        //           lat: -23.001,
        //           lng: -43.33086
        //         },
        //         {
        //           lat: -23.00116,
        //           lng: -43.32995
        //         },
        //         {
        //           lat: -23.00118,
        //           lng: -43.32983
        //         },
        //         {
        //           lat: -23.00122,
        //           lng: -43.32967
        //         },
        //         {
        //           lat: -23.00139,
        //           lng: -43.32917
        //         },
        //         {
        //           lat: -23.00158,
        //           lng: -43.32864
        //         },
        //         {
        //           lat: -23.0016,
        //           lng: -43.32859
        //         },
        //         {
        //           lat: -23.00169,
        //           lng: -43.32838
        //         },
        //         {
        //           lat: -23.00206,
        //           lng: -43.32745
        //         },
        //         {
        //           lat: -23.00287,
        //           lng: -43.32542
        //         },
        //         {
        //           lat: -23.00295,
        //           lng: -43.32523
        //         },
        //         {
        //           lat: -23.00306,
        //           lng: -43.32498
        //         },
        //         {
        //           lat: -23.00322,
        //           lng: -43.32458
        //         },
        //         {
        //           lat: -23.00327,
        //           lng: -43.32446
        //         },
        //         {
        //           lat: -23.0033,
        //           lng: -43.32439
        //         },
        //         {
        //           lat: -23.00344,
        //           lng: -43.324
        //         },
        //         {
        //           lat: -23.0035,
        //           lng: -43.32385
        //         },
        //         {
        //           lat: -23.00354,
        //           lng: -43.32376
        //         },
        //         {
        //           lat: -23.0037,
        //           lng: -43.32337
        //         },
        //         {
        //           lat: -23.00403,
        //           lng: -43.32258
        //         },
        //         {
        //           lat: -23.00405,
        //           lng: -43.32252
        //         },
        //         {
        //           lat: -23.00411,
        //           lng: -43.32239
        //         },
        //         {
        //           lat: -23.00429,
        //           lng: -43.32198
        //         },
        //         {
        //           lat: -23.00437,
        //           lng: -43.32182
        //         },
        //         {
        //           lat: -23.00447,
        //           lng: -43.32162
        //         },
        //         {
        //           lat: -23.00453,
        //           lng: -43.32147
        //         },
        //         {
        //           lat: -23.0046,
        //           lng: -43.32127
        //         },
        //         {
        //           lat: -23.00474,
        //           lng: -43.3209
        //         },
        //         {
        //           lat: -23.00554,
        //           lng: -43.31871
        //         },
        //         {
        //           lat: -23.00565,
        //           lng: -43.31847
        //         },
        //         {
        //           lat: -23.00565,
        //           lng: -43.31847
        //         },
        //         {
        //           lat: -23.005917708417062,
        //           lng: -43.317659497261054
        //         },
        //         {
        //           lat: -23.006115218525277,
        //           lng: -43.31720352172852
        //         },
        //         {
        //           lat: -23.006327541569178,
        //           lng: -43.31647396087647
        //         },
        //         {
        //           lat: -23.006436171834554,
        //           lng: -43.31598579883576
        //         },
        //         {
        //           lat: -23.006500362404815,
        //           lng: -43.31557273864747
        //         },
        //         {
        //           lat: -23.00651517560899,
        //           lng: -43.31488877534866
        //         },
        //         {
        //           lat: -23.00661146139653,
        //           lng: -43.314228951931
        //         },
        //         {
        //           lat: -23.00672749802357,
        //           lng: -43.3135610818863
        //         },
        //         {
        //           lat: -23.006801563903558,
        //           lng: -43.312627673149116
        //         }],
        //         latitude: "",
        //         longitude: "",
        //         way: 9432
        //       },
        //       {
        //         id: 3973,
        //         name: "Terminal Alvorada",
        //         clientId: 209,
        //         latitude: "-23.000942",
        //         longitude: "-43.36583300000001",
        //         polyLine: [],
        //         way: 9432
        //       },
        //       {
        //         id: 61,
        //         name: "12 - PINGO D'AGUA X ALVORADA ( EXPRESSO )",
        //         clientId: 209,
        //         polyLine: [
        //           {
        //             lat: -23.00103742067709,
        //             lng: -43.365840728836076
        //           },
        //           {
        //             lat: -23.00105397257643,
        //             lng: -43.366151258583045
        //           },
        //           {
        //             lat: -23.001090374181167,
        //             lng: -43.36740808316233
        //           },
        //           {
        //             lat: -23.001093761182194,
        //             lng: -43.368041859870004
        //           },
        //           {
        //             lat: -23.001026323606368,
        //             lng: -43.368208544519064
        //           },
        //           {
        //             lat: -23.000859280453952,
        //             lng: -43.36827579358942
        //           },
        //           {
        //             lat: -23.000785634688764,
        //             lng: -43.368186036509826
        //           },
        //           {
        //             lat: -23.000746342818367,
        //             lng: -43.368095560416805
        //           },
        //           {
        //             lat: -23.00073904174393,
        //             lng: -43.367607757882865
        //           },
        //           {
        //             lat: -23.00073045326052,
        //             lng: -43.36726193267333
        //           },
        //           {
        //             lat: -23.000773069500013,
        //             lng: -43.36709974890471
        //           },
        //           {
        //             lat: -23.000870915721478,
        //             lng: -43.36700524597529
        //           },
        //           {
        //             lat: -23.00095440437547,
        //             lng: -43.366901668121216
        //           },
        //           {
        //             lat: -23.000959114155076,
        //             lng: -43.36675868408764
        //           },
        //           {
        //             lat: -23.00088493098941,
        //             lng: -43.3666952386979
        //           },
        //           {
        //             lat: -23.000781177160324,
        //             lng: -43.366671562630074
        //           },
        //           {
        //             lat: -23.000593478108524,
        //             lng: -43.36677663259508
        //           },
        //           {
        //             lat: -23.000182361740162,
        //             lng: -43.36681728504186
        //           },
        //           {
        //             lat: -23.00016254725097,
        //             lng: -43.3678319986725
        //           },
        //           {
        //             lat: -23.000135263184163,
        //             lng: -43.36900580125808
        //           },
        //           {
        //             lat: -23.000072681738132,
        //             lng: -43.36957326389313
        //           },
        //           {
        //             lat: -23.000135038009454,
        //             lng: -43.370094721565295
        //           },
        //           {
        //             lat: -23.00012121640835,
        //             lng: -43.37074314617155
        //           },
        //           {
        //             lat: -23.00011196068494,
        //             lng: -43.37131818916322
        //           },
        //           {
        //             lat: -23.000038511366203,
        //             lng: -43.37201311904914
        //           },
        //           {
        //             lat: -23.000078263229966,
        //             lng: -43.373089941139256
        //           },
        //           {
        //             lat: -23.000100000000003,
        //             lng: -43.37353999999999
        //           },
        //           {
        //             lat: -23.00015,
        //             lng: -43.373760000000004
        //           },
        //           {
        //             lat: -23.00018,
        //             lng: -43.37581
        //           },
        //           {
        //             lat: -23.000230000000002,
        //             lng: -43.37829999999997
        //           },
        //           {
        //             lat: -23.000300000000003,
        //             lng: -43.38207
        //           },
        //           {
        //             lat: -23.00033,
        //             lng: -43.38548000000003
        //           },
        //           {
        //             lat: -23.00037,
        //             lng: -43.388270000000034
        //           },
        //           {
        //             lat: -23.00034,
        //             lng: -43.38940000000002
        //           },
        //           {
        //             lat: -23.000290000000003,
        //             lng: -43.391279999999995
        //           },
        //           {
        //             lat: -23.00024,
        //             lng: -43.39399000000003
        //           },
        //           {
        //             lat: -23.00015,
        //             lng: -43.396799999999985
        //           },
        //           {
        //             lat: -23.00005,
        //             lng: -43.40029000000004
        //           },
        //           {
        //             lat: -23.000000000000004,
        //             lng: -43.40129000000002
        //           },
        //           {
        //             lat: -22.99989,
        //             lng: -43.40350000000001
        //           },
        //           {
        //             lat: -22.999820000000003,
        //             lng: -43.405820000000006
        //           },
        //           {
        //             lat: -22.99977,
        //             lng: -43.40778999999998
        //           },
        //           {
        //             lat: -22.999750000000002,
        //             lng: -43.40848
        //           },
        //           {
        //             lat: -22.999760000000002,
        //             lng: -43.40960000000001
        //           },
        //           {
        //             lat: -22.999850000000002,
        //             lng: -43.41165999999998
        //           },
        //           {
        //             lat: -22.999950000000002,
        //             lng: -43.41278
        //           },
        //           {
        //             lat: -23.00007,
        //             lng: -43.41383000000002
        //           },
        //           {
        //             lat: -23.000300000000003,
        //             lng: -43.41534999999999
        //           },
        //           {
        //             lat: -23.00046,
        //             lng: -43.41623000000004
        //           },
        //           {
        //             lat: -23.00062,
        //             lng: -43.41696999999999
        //           },
        //           {
        //             lat: -23.000950000000003,
        //             lng: -43.41836999999998
        //           },
        //           {
        //             lat: -23.00128,
        //             lng: -43.41949999999997
        //           },
        //           {
        //             lat: -23.00159,
        //             lng: -43.420500000000004
        //           },
        //           {
        //             lat: -23.001980000000003,
        //             lng: -43.42168000000004
        //           },
        //           {
        //             lat: -23.0026,
        //             lng: -43.42357000000004
        //           },
        //           {
        //             lat: -23.00316,
        //             lng: -43.42523
        //           },
        //           {
        //             lat: -23.003490000000003,
        //             lng: -43.42626999999999
        //           },
        //           {
        //             lat: -23.004730000000002,
        //             lng: -43.43002999999999
        //           },
        //           {
        //             lat: -23.005070000000003,
        //             lng: -43.431100000000015
        //           },
        //           {
        //             lat: -23.005540000000003,
        //             lng: -43.43273999999997
        //           },
        //           {
        //             lat: -23.00656,
        //             lng: -43.43673000000001
        //           },
        //           {
        //             lat: -23.006880000000002,
        //             lng: -43.43798000000004
        //           },
        //           {
        //             lat: -23.0072,
        //             lng: -43.439269999999965
        //           },
        //           {
        //             lat: -23.007510000000003,
        //             lng: -43.44038999999998
        //           },
        //           {
        //             lat: -23.00777,
        //             lng: -43.44144
        //           },
        //           {
        //             lat: -23.008000000000003,
        //             lng: -43.44236000000001
        //           },
        //           {
        //             lat: -23.00852,
        //             lng: -43.4443
        //           },
        //           {
        //             lat: -23.009990000000002,
        //             lng: -43.45015999999998
        //           },
        //           {
        //             lat: -23.01094,
        //             lng: -43.45391000000001
        //           },
        //           {
        //             lat: -23.011200000000002,
        //             lng: -43.45487000000003
        //           },
        //           {
        //             lat: -23.011550000000003,
        //             lng: -43.45627000000002
        //           },
        //           {
        //             lat: -23.012120000000003,
        //             lng: -43.45848000000001
        //           },
        //           {
        //             lat: -23.012330000000002,
        //             lng: -43.45934
        //           },
        //           {
        //             lat: -23.01321,
        //             lng: -43.46266000000003
        //           },
        //           {
        //             lat: -23.013460000000002,
        //             lng: -43.46361999999999
        //           },
        //           {
        //             lat: -23.014830000000003,
        //             lng: -43.46899000000002
        //           },
        //           {
        //             lat: -23.01529,
        //             lng: -43.47077999999999
        //           },
        //           {
        //             lat: -23.01592,
        //             lng: -43.47320000000002
        //           },
        //           {
        //             lat: -23.016050000000003,
        //             lng: -43.47388000000001
        //           },
        //           {
        //             lat: -23.01619,
        //             lng: -43.47446000000002
        //           },
        //           {
        //             lat: -23.016330000000004,
        //             lng: -43.47501
        //           },
        //           {
        //             lat: -23.01706,
        //             lng: -43.47782000000001
        //           },
        //           {
        //             lat: -23.018,
        //             lng: -43.48154999999997
        //           },
        //           {
        //             lat: -23.018240000000002,
        //             lng: -43.482470000000035
        //           },
        //           {
        //             lat: -23.018510000000003,
        //             lng: -43.48356000000001
        //           },
        //           {
        //             lat: -23.01874,
        //             lng: -43.484339999999975
        //           },
        //           {
        //             lat: -23.019170000000003,
        //             lng: -43.48606000000001
        //           },
        //           {
        //             lat: -23.019350000000003,
        //             lng: -43.48688000000004
        //           },
        //           {
        //             lat: -23.020220000000002,
        //             lng: -43.49022000000002
        //           },
        //           {
        //             lat: -23.020410000000002,
        //             lng: -43.491250000000036
        //           },
        //           {
        //             lat: -23.020570000000003,
        //             lng: -43.4923
        //           },
        //           {
        //             lat: -23.02062,
        //             lng: -43.49263000000002
        //           },
        //           {
        //             lat: -23.020740000000004,
        //             lng: -43.49373000000003
        //           },
        //           {
        //             lat: -23.020850000000003,
        //             lng: -43.49505999999997
        //           },
        //           {
        //             lat: -23.020880000000002,
        //             lng: -43.49577999999997
        //           },
        //           {
        //             lat: -23.020850000000003,
        //             lng: -43.49651
        //           },
        //           {
        //             lat: -23.020780000000002,
        //             lng: -43.49739999999997
        //           },
        //           {
        //             lat: -23.0207,
        //             lng: -43.498440000000016
        //           },
        //           {
        //             lat: -23.020650000000003,
        //             lng: -43.49892999999997
        //           },
        //           {
        //             lat: -23.020580000000002,
        //             lng: -43.49959000000001
        //           },
        //           {
        //             lat: -23.02044,
        //             lng: -43.500350000000026
        //           },
        //           {
        //             lat: -23.020290000000003,
        //             lng: -43.50110000000001
        //           },
        //           {
        //             lat: -23.02014,
        //             lng: -43.50171999999998
        //           },
        //           {
        //             lat: -23.01959,
        //             lng: -43.50371999999999
        //           },
        //           {
        //             lat: -23.019440000000003,
        //             lng: -43.50414000000001
        //           },
        //           {
        //             lat: -23.01923,
        //             lng: -43.504610000000014
        //           },
        //           {
        //             lat: -23.01912,
        //             lng: -43.50490000000002
        //           },
        //           {
        //             lat: -23.018900000000002,
        //             lng: -43.50544000000002
        //           },
        //           {
        //             lat: -23.018720000000002,
        //             lng: -43.50612000000001
        //           },
        //           {
        //             lat: -23.01863,
        //             lng: -43.50661000000002
        //           },
        //           {
        //             lat: -23.018580000000004,
        //             lng: -43.50707
        //           },
        //           {
        //             lat: -23.018430000000002,
        //             lng: -43.507709999999975
        //           },
        //           {
        //             lat: -23.018,
        //             lng: -43.509379999999965
        //           },
        //           {
        //             lat: -23.017680000000002,
        //             lng: -43.510459999999966
        //           },
        //           {
        //             lat: -23.017590000000002,
        //             lng: -43.51084000000003
        //           },
        //           {
        //             lat: -23.01697,
        //             lng: -43.51312999999999
        //           },
        //           {
        //             lat: -23.01677,
        //             lng: -43.51387
        //           },
        //           {
        //             lat: -23.016180000000002,
        //             lng: -43.51597000000004
        //           },
        //           {
        //             lat: -23.01592,
        //             lng: -43.51714000000004
        //           },
        //           {
        //             lat: -23.015530000000002,
        //             lng: -43.51974999999999
        //           },
        //           {
        //             lat: -23.015390000000004,
        //             lng: -43.52098000000001
        //           },
        //           {
        //             lat: -23.01537,
        //             lng: -43.52168
        //           },
        //           {
        //             lat: -23.015410000000003,
        //             lng: -43.52217000000002
        //           },
        //           {
        //             lat: -23.01536,
        //             lng: -43.52251000000001
        //           },
        //           {
        //             lat: -23.01526,
        //             lng: -43.52307000000002
        //           },
        //           {
        //             lat: -23.015120000000003,
        //             lng: -43.523619999999994
        //           },
        //           {
        //             lat: -23.014750000000003,
        //             lng: -43.52469000000002
        //           },
        //           {
        //             lat: -23.014280000000003,
        //             lng: -43.52591000000001
        //           },
        //           {
        //             lat: -23.013700000000004,
        //             lng: -43.52724999999998
        //           },
        //           {
        //             lat: -23.01312,
        //             lng: -43.52852999999999
        //           },
        //           {
        //             lat: -23.011760000000002,
        //             lng: -43.531079999999974
        //           },
        //           {
        //             lat: -23.011370000000003,
        //             lng: -43.531920000000014
        //           },
        //           {
        //             lat: -23.011170000000003,
        //             lng: -43.53251999999998
        //           },
        //           {
        //             lat: -23.011080000000003,
        //             lng: -43.53287
        //           },
        //           {
        //             lat: -23.01103,
        //             lng: -43.533199999999965
        //           },
        //           {
        //             lat: -23.011000000000003,
        //             lng: -43.53424000000001
        //           },
        //           {
        //             lat: -23.010980000000004,
        //             lng: -43.53505000000001
        //           },
        //           {
        //             lat: -23.010910000000003,
        //             lng: -43.53561000000002
        //           },
        //           {
        //             lat: -23.01032,
        //             lng: -43.537739999999985
        //           },
        //           {
        //             lat: -23.0101,
        //             lng: -43.538520000000005
        //           },
        //           {
        //             lat: -23.009950000000003,
        //             lng: -43.53886
        //           },
        //           {
        //             lat: -23.009790000000002,
        //             lng: -43.53913
        //           },
        //           {
        //             lat: -23.00917,
        //             lng: -43.54009000000002
        //           },
        //           {
        //             lat: -23.00859,
        //             lng: -43.540970000000016
        //           },
        //           {
        //             lat: -23.00832,
        //             lng: -43.54142999999999
        //           },
        //           {
        //             lat: -23.008070000000004,
        //             lng: -43.54191000000003
        //           },
        //           {
        //             lat: -23.00785,
        //             lng: -43.542460000000005
        //           },
        //           {
        //             lat: -23.00776,
        //             lng: -43.54269999999997
        //           },
        //           {
        //             lat: -23.007600000000004,
        //             lng: -43.54329999999999
        //           },
        //           {
        //             lat: -23.0075,
        //             lng: -43.543859999999995
        //           },
        //           {
        //             lat: -23.007450000000002,
        //             lng: -43.54455999999999
        //           },
        //           {
        //             lat: -23.007460000000002,
        //             lng: -43.545600000000036
        //           },
        //           {
        //             lat: -23.0075,
        //             lng: -43.54784000000001
        //           },
        //           {
        //             lat: -23.00748,
        //             lng: -43.54845999999998
        //           },
        //           {
        //             lat: -23.007440000000003,
        //             lng: -43.54872
        //           },
        //           {
        //             lat: -23.007230000000003,
        //             lng: -43.54961000000003
        //           },
        //           {
        //             lat: -23.006990000000002,
        //             lng: -43.55063999999999
        //           },
        //           {
        //             lat: -23.00675,
        //             lng: -43.55165999999997
        //           },
        //           {
        //             lat: -23.00569,
        //             lng: -43.556129999999996
        //           },
        //           {
        //             lat: -23.005560000000003,
        //             lng: -43.556569999999965
        //           },
        //           {
        //             lat: -23.00532,
        //             lng: -43.55749000000003
        //           },
        //           {
        //             lat: -23.005080000000003,
        //             lng: -43.55848000000003
        //           },
        //           {
        //             lat: -23.00468,
        //             lng: -43.56043999999997
        //           },
        //           {
        //             lat: -23.004510000000003,
        //             lng: -43.561199999999985
        //           },
        //           {
        //             lat: -23.00392,
        //             lng: -43.56364000000002
        //           },
        //           {
        //             lat: -23.003590000000003,
        //             lng: -43.56515000000002
        //           },
        //           {
        //             lat: -23.00007,
        //             lng: -43.58006999999998
        //           },
        //           {
        //             lat: -22.999840000000003,
        //             lng: -43.58089000000001
        //           },
        //           {
        //             lat: -22.9995,
        //             lng: -43.58222000000001
        //           },
        //           {
        //             lat: -22.999190000000002,
        //             lng: -43.58352000000002
        //           },
        //           {
        //             lat: -22.99895,
        //             lng: -43.584749999999985
        //           },
        //           {
        //             lat: -22.998440000000002,
        //             lng: -43.586959999999976
        //           },
        //           {
        //             lat: -22.997400000000003,
        //             lng: -43.591380000000015
        //           },
        //           {
        //             lat: -22.997280000000003,
        //             lng: -43.59183999999999
        //           },
        //           {
        //             lat: -22.997110000000003,
        //             lng: -43.59228999999999
        //           },
        //           {
        //             lat: -22.9969,
        //             lng: -43.592719999999986
        //           },
        //           {
        //             lat: -22.996550000000003,
        //             lng: -43.59327000000002
        //           },
        //           {
        //             lat: -22.99624,
        //             lng: -43.59366
        //           },
        //           {
        //             lat: -22.99555,
        //             lng: -43.59441000000004
        //           },
        //           {
        //             lat: -22.995160000000002,
        //             lng: -43.59491000000003
        //           },
        //           {
        //             lat: -22.99462,
        //             lng: -43.59564999999998
        //           },
        //           {
        //             lat: -22.994410000000002,
        //             lng: -43.59590000000003
        //           },
        //           {
        //             lat: -22.99367,
        //             lng: -43.596749999999986
        //           },
        //           {
        //             lat: -22.992890000000003,
        //             lng: -43.59770000000003
        //           },
        //           {
        //             lat: -22.991750000000003,
        //             lng: -43.59906000000001
        //           },
        //           {
        //             lat: -22.991650000000003,
        //             lng: -43.59915999999998
        //           },
        //           {
        //             lat: -22.99151,
        //             lng: -43.599339999999984
        //           },
        //           {
        //             lat: -22.991270000000004,
        //             lng: -43.59973000000002
        //           },
        //           {
        //             lat: -22.99067,
        //             lng: -43.600810000000024
        //           },
        //           {
        //             lat: -22.990370000000002,
        //             lng: -43.60122999999999
        //           },
        //           {
        //             lat: -22.989900000000002,
        //             lng: -43.60178000000002
        //           },
        //           {
        //             lat: -22.989020000000004,
        //             lng: -43.6028
        //           },
        //           {
        //             lat: -22.988750000000003,
        //             lng: -43.60309000000001
        //           },
        //           {
        //             lat: -22.98797,
        //             lng: -43.60401000000002
        //           },
        //           {
        //             lat: -22.98742,
        //             lng: -43.60464000000002
        //           },
        //           {
        //             lat: -22.98702,
        //             lng: -43.605050000000006
        //           },
        //           {
        //             lat: -22.986210000000003,
        //             lng: -43.605999999999995
        //           },
        //           {
        //             lat: -22.984750000000002,
        //             lng: -43.60768999999999
        //           },
        //           {
        //             lat: -22.98402,
        //             lng: -43.60849000000002
        //           },
        //           {
        //             lat: -22.983690000000003,
        //             lng: -43.608780000000024
        //           },
        //           {
        //             lat: -22.983300000000003,
        //             lng: -43.609069999999974
        //           },
        //           {
        //             lat: -22.982850000000003,
        //             lng: -43.609339999999975
        //           },
        //           {
        //             lat: -22.982100000000003,
        //             lng: -43.60966000000002
        //           },
        //           {
        //             lat: -22.97491,
        //             lng: -43.61235999999997
        //           },
        //           {
        //             lat: -22.974290000000003,
        //             lng: -43.61259000000001
        //           },
        //           {
        //             lat: -22.97359,
        //             lng: -43.61286999999999
        //           },
        //           {
        //             lat: -22.97324,
        //             lng: -43.61306000000002
        //           },
        //           {
        //             lat: -22.97277,
        //             lng: -43.61338999999998
        //           },
        //           {
        //             lat: -22.972360000000002,
        //             lng: -43.61374999999998
        //           },
        //           {
        //             lat: -22.971980000000002,
        //             lng: -43.61414000000002
        //           },
        //           {
        //             lat: -22.971670000000003,
        //             lng: -43.61448999999999
        //           },
        //           {
        //             lat: -22.97154,
        //             lng: -43.61469999999997
        //           },
        //           {
        //             lat: -22.970740000000003,
        //             lng: -43.61554000000001
        //           },
        //           {
        //             lat: -22.9705,
        //             lng: -43.615800000000036
        //           },
        //           {
        //             lat: -22.970090000000003,
        //             lng: -43.616250000000036
        //           },
        //           {
        //             lat: -22.96984,
        //             lng: -43.61644000000001
        //           },
        //           {
        //             lat: -22.96965,
        //             lng: -43.61664999999999
        //           },
        //           {
        //             lat: -22.96931,
        //             lng: -43.617020000000025
        //           },
        //           {
        //             lat: -22.969040000000003,
        //             lng: -43.61736000000002
        //           },
        //           {
        //             lat: -22.968850000000003,
        //             lng: -43.61766
        //           },
        //           {
        //             lat: -22.96863,
        //             lng: -43.61808000000002
        //           },
        //           {
        //             lat: -22.968470000000003,
        //             lng: -43.61847999999998
        //           },
        //           {
        //             lat: -22.968380000000003,
        //             lng: -43.61878999999999
        //           },
        //           {
        //             lat: -22.968290000000003,
        //             lng: -43.619249999999965
        //           },
        //           {
        //             lat: -22.96824,
        //             lng: -43.619609999999966
        //           },
        //           {
        //             lat: -22.968220000000002,
        //             lng: -43.62011000000001
        //           },
        //           {
        //             lat: -22.96826,
        //             lng: -43.62068999999997
        //           },
        //           {
        //             lat: -22.968390000000003,
        //             lng: -43.62143000000003
        //           },
        //           {
        //             lat: -22.96863,
        //             lng: -43.622630000000015
        //           },
        //           {
        //             lat: -22.968780000000002,
        //             lng: -43.62329
        //           },
        //           {
        //             lat: -22.969070000000002,
        //             lng: -43.62458000000004
        //           },
        //           {
        //             lat: -22.969630000000002,
        //             lng: -43.62732
        //           },
        //           {
        //             lat: -22.96983,
        //             lng: -43.62822
        //           },
        //           {
        //             lat: -22.970000000000002,
        //             lng: -43.62907999999999
        //           },
        //           {
        //             lat: -22.970090000000003,
        //             lng: -43.62977999999998
        //           },
        //           {
        //             lat: -22.970100000000002,
        //             lng: -43.630210000000034
        //           },
        //           {
        //             lat: -22.97005,
        //             lng: -43.630840000000035
        //           },
        //           {
        //             lat: -22.969970000000004,
        //             lng: -43.631280000000004
        //           },
        //           {
        //             lat: -22.96965,
        //             lng: -43.63249999999999
        //           },
        //           {
        //             lat: -22.968300000000003,
        //             lng: -43.63713999999999
        //           },
        //           {
        //             lat: -22.967750000000002,
        //             lng: -43.63905999999997
        //           },
        //           {
        //             lat: -22.96721,
        //             lng: -43.64085
        //           },
        //           {
        //             lat: -22.965850000000003,
        //             lng: -43.64570000000003
        //           },
        //           {
        //             lat: -22.965670000000003,
        //             lng: -43.646150000000034
        //           },
        //           {
        //             lat: -22.96542,
        //             lng: -43.64661000000001
        //           },
        //           {
        //             lat: -22.96527,
        //             lng: -43.646799999999985
        //           },
        //           {
        //             lat: -22.965100000000003,
        //             lng: -43.646960000000036
        //           },
        //           {
        //             lat: -22.964840000000002,
        //             lng: -43.64719000000002
        //           }
        //         ],
        //         latitude: "",
        //         longitude: "",
        //         way: 9432
        //       },
        //       {
        //         id: 3968,
        //         name: "Estação Mato Alto",
        //         clientId: 209,
        //         latitude: "-22.993807",
        //         longitude: "-43.596798000000035",
        //         polyLine: [],
        //         way: 9432
        //       }
        //     ],
        //     end: {
        //       id: 20,
        //       name: "Caminhada",
        //       clientId: 209,
        //       walk: "Cerca de 9 min, 650 m"
        //     },
        //     journeyTimeText: "1h 15min"
        //   },
        //   {
        //     start: {
        //       id: 30,
        //       name: "Caminhada",
        //       clientId: 209,
        //       walk: "Cerca de 9 min, 650 m"
        //     },
        //     itinerary: [
        //       {
        //         id: 4025,
        //         name: "Estação Rio 2",
        //         clientId: 209,
        //         latitude: "-22.973281",
        //         longitude: "-43.38278100000002",
        //         polyLine: [],
        //         way: 9432
        //       },
        //       {
        //         id: 3520,
        //         name: "53B - SULACAP X JD. OCEANICO (PARADOR)",
        //         clientId: 209,
        //         polyLine: [{
        //           lat: -22.88467454,
        //           lng: -43.39959319000002
        //         },
        //         {
        //           lat: -22.883855607097715,
        //           lng: -43.399509787559516
        //         },
        //         {
        //           lat: -22.883223002541275,
        //           lng: -43.39942395687103
        //         },
        //         {
        //           lat: -22.883143926764436,
        //           lng: -43.39946150779724
        //         },
        //         {
        //           lat: -22.883099446619713,
        //           lng: -43.399515151977546
        //         },
        //         {
        //           lat: -22.882946237120805,
        //           lng: -43.400137424468994
        //         },
        //         {
        //           lat: -22.8829511793654,
        //           lng: -43.40022325515748
        //         },
        //         {
        //           lat: -22.88298,
        //           lng: -43.40031
        //         },
        //         {
        //           lat: -22.88495,
        //           lng: -43.40067
        //         },
        //         {
        //           lat: -22.88552,
        //           lng: -43.40077
        //         },
        //         {
        //           lat: -22.88631,
        //           lng: -43.40091
        //         },
        //         {
        //           lat: -22.88683,
        //           lng: -43.40101
        //         },
        //         {
        //           lat: -22.8872,
        //           lng: -43.40108
        //         },
        //         {
        //           lat: -22.88743,
        //           lng: -43.4011
        //         },
        //         {
        //           lat: -22.88767,
        //           lng: -43.40109
        //         },
        //         {
        //           lat: -22.88795,
        //           lng: -43.40107
        //         },
        //         {
        //           lat: -22.88921,
        //           lng: -43.40095
        //         },
        //         {
        //           lat: -22.8904,
        //           lng: -43.40083
        //         },
        //         {
        //           lat: -22.89107,
        //           lng: -43.40072
        //         },
        //         {
        //           lat: -22.89165,
        //           lng: -43.40055
        //         },
        //         {
        //           lat: -22.89247,
        //           lng: -43.40028
        //         },
        //         {
        //           lat: -22.89335,
        //           lng: -43.39999
        //         },
        //         {
        //           lat: -22.89403,
        //           lng: -43.39986
        //         },
        //         {
        //           lat: -22.89512,
        //           lng: -43.39979
        //         },
        //         {
        //           lat: -22.89544,
        //           lng: -43.39972
        //         },
        //         {
        //           lat: -22.89591,
        //           lng: -43.39952
        //         },
        //         {
        //           lat: -22.896,
        //           lng: -43.39948
        //         },
        //         {
        //           lat: -22.89608,
        //           lng: -43.39943
        //         },
        //         {
        //           lat: -22.8967,
        //           lng: -43.39908
        //         },
        //         {
        //           lat: -22.89711,
        //           lng: -43.39879
        //         },
        //         {
        //           lat: -22.89746,
        //           lng: -43.39848
        //         },
        //         {
        //           lat: -22.89755,
        //           lng: -43.39842
        //         },
        //         {
        //           lat: -22.89787,
        //           lng: -43.3982
        //         },
        //         {
        //           lat: -22.89822,
        //           lng: -43.39802
        //         },
        //         {
        //           lat: -22.89868,
        //           lng: -43.39786
        //         },
        //         {
        //           lat: -22.89884,
        //           lng: -43.39781
        //         },
        //         {
        //           lat: -22.89894,
        //           lng: -43.39779
        //         },
        //         {
        //           lat: -22.89898,
        //           lng: -43.39778
        //         },
        //         {
        //           lat: -22.89906,
        //           lng: -43.39778
        //         },
        //         {
        //           lat: -22.89916,
        //           lng: -43.39779
        //         },
        //         {
        //           lat: -22.89928,
        //           lng: -43.39782
        //         },
        //         {
        //           lat: -22.89938,
        //           lng: -43.39785
        //         },
        //         {
        //           lat: -22.8995,
        //           lng: -43.39788
        //         },
        //         {
        //           lat: -22.89959,
        //           lng: -43.3979
        //         },
        //         {
        //           lat: -22.89969,
        //           lng: -43.39793
        //         },
        //         {
        //           lat: -22.89981,
        //           lng: -43.39796
        //         },
        //         {
        //           lat: -22.89992,
        //           lng: -43.398
        //         },
        //         {
        //           lat: -22.90003,
        //           lng: -43.39803
        //         },
        //         {
        //           lat: -22.91085,
        //           lng: -43.40118
        //         },
        //         {
        //           lat: -22.91114,
        //           lng: -43.40124
        //         },
        //         {
        //           lat: -22.91139,
        //           lng: -43.40126
        //         },
        //         {
        //           lat: -22.9117,
        //           lng: -43.40124
        //         },
        //         {
        //           lat: -22.91202,
        //           lng: -43.40118
        //         },
        //         {
        //           lat: -22.91231,
        //           lng: -43.40109
        //         },
        //         {
        //           lat: -22.9126,
        //           lng: -43.40097
        //         },
        //         {
        //           lat: -22.91286,
        //           lng: -43.40081
        //         },
        //         {
        //           lat: -22.91306,
        //           lng: -43.40066
        //         },
        //         {
        //           lat: -22.91336,
        //           lng: -43.40041
        //         },
        //         {
        //           lat: -22.91468,
        //           lng: -43.39918
        //         },
        //         {
        //           lat: -22.91488,
        //           lng: -43.39896
        //         },
        //         {
        //           lat: -22.91514,
        //           lng: -43.3987
        //         },
        //         {
        //           lat: -22.91579,
        //           lng: -43.3981
        //         },
        //         {
        //           lat: -22.91623,
        //           lng: -43.39769
        //         },
        //         {
        //           lat: -22.91647,
        //           lng: -43.39749
        //         },
        //         {
        //           lat: -22.91672,
        //           lng: -43.39731
        //         },
        //         {
        //           lat: -22.91697,
        //           lng: -43.39715
        //         },
        //         {
        //           lat: -22.91715,
        //           lng: -43.39705
        //         },
        //         {
        //           lat: -22.91744,
        //           lng: -43.39691
        //         },
        //         {
        //           lat: -22.91768,
        //           lng: -43.39682
        //         },
        //         {
        //           lat: -22.9179,
        //           lng: -43.39674
        //         },
        //         {
        //           lat: -22.91818,
        //           lng: -43.39667
        //         },
        //         {
        //           lat: -22.91844,
        //           lng: -43.39663
        //         },
        //         {
        //           lat: -22.91871,
        //           lng: -43.39662
        //         },
        //         {
        //           lat: -22.91911,
        //           lng: -43.39661
        //         },
        //         {
        //           lat: -22.91946,
        //           lng: -43.39663
        //         },
        //         {
        //           lat: -22.91982,
        //           lng: -43.39669
        //         },
        //         {
        //           lat: -22.92026,
        //           lng: -43.39681
        //         },
        //         {
        //           lat: -22.92062,
        //           lng: -43.39694
        //         },
        //         {
        //           lat: -22.92105,
        //           lng: -43.39713
        //         },
        //         {
        //           lat: -22.92225,
        //           lng: -43.39765
        //         },
        //         {
        //           lat: -22.92282,
        //           lng: -43.39787
        //         },
        //         {
        //           lat: -22.92317,
        //           lng: -43.39796
        //         },
        //         {
        //           lat: -22.92347,
        //           lng: -43.39801
        //         },
        //         {
        //           lat: -22.92374,
        //           lng: -43.39802
        //         },
        //         {
        //           lat: -22.92398,
        //           lng: -43.39801
        //         },
        //         {
        //           lat: -22.92422,
        //           lng: -43.39799
        //         },
        //         {
        //           lat: -22.92446,
        //           lng: -43.39793
        //         },
        //         {
        //           lat: -22.92468,
        //           lng: -43.39784
        //         },
        //         {
        //           lat: -22.92485,
        //           lng: -43.39776
        //         },
        //         {
        //           lat: -22.92516,
        //           lng: -43.39759
        //         },
        //         {
        //           lat: -22.92528,
        //           lng: -43.39753
        //         },
        //         {
        //           lat: -22.9255,
        //           lng: -43.3974
        //         },
        //         {
        //           lat: -22.92701,
        //           lng: -43.39658
        //         },
        //         {
        //           lat: -22.92747,
        //           lng: -43.39636
        //         },
        //         {
        //           lat: -22.92828,
        //           lng: -43.39594
        //         },
        //         {
        //           lat: -22.92856,
        //           lng: -43.39577
        //         },
        //         {
        //           lat: -22.92877,
        //           lng: -43.39565
        //         },
        //         {
        //           lat: -22.92902,
        //           lng: -43.39553
        //         },
        //         {
        //           lat: -22.92931,
        //           lng: -43.39542
        //         },
        //         {
        //           lat: -22.92972,
        //           lng: -43.39531
        //         },
        //         {
        //           lat: -22.93008,
        //           lng: -43.39524
        //         },
        //         {
        //           lat: -22.93055,
        //           lng: -43.39519
        //         },
        //         {
        //           lat: -22.93083,
        //           lng: -43.39521
        //         },
        //         {
        //           lat: -22.9312,
        //           lng: -43.39526
        //         },
        //         {
        //           lat: -22.9315,
        //           lng: -43.39532
        //         },
        //         {
        //           lat: -22.93354,
        //           lng: -43.39569
        //         },
        //         {
        //           lat: -22.93392,
        //           lng: -43.39575
        //         },
        //         {
        //           lat: -22.93441,
        //           lng: -43.39578
        //         },
        //         {
        //           lat: -22.93481,
        //           lng: -43.39577
        //         },
        //         {
        //           lat: -22.93519,
        //           lng: -43.39572
        //         },
        //         {
        //           lat: -22.93581,
        //           lng: -43.3956
        //         },
        //         {
        //           lat: -22.93694,
        //           lng: -43.39538
        //         },
        //         {
        //           lat: -22.93778,
        //           lng: -43.3952
        //         },
        //         {
        //           lat: -22.93805,
        //           lng: -43.39515
        //         },
        //         {
        //           lat: -22.93918,
        //           lng: -43.39498
        //         },
        //         {
        //           lat: -22.94004,
        //           lng: -43.39492
        //         },
        //         {
        //           lat: -22.94132,
        //           lng: -43.39475
        //         },
        //         {
        //           lat: -22.94224,
        //           lng: -43.39468
        //         },
        //         {
        //           lat: -22.94242,
        //           lng: -43.39466
        //         },
        //         {
        //           lat: -22.94332,
        //           lng: -43.39455
        //         },
        //         {
        //           lat: -22.94441,
        //           lng: -43.3944
        //         },
        //         {
        //           lat: -22.94453,
        //           lng: -43.39439
        //         },
        //         {
        //           lat: -22.94512,
        //           lng: -43.39431
        //         },
        //         {
        //           lat: -22.94558,
        //           lng: -43.39418
        //         },
        //         {
        //           lat: -22.94598,
        //           lng: -43.39402
        //         },
        //         {
        //           lat: -22.94637,
        //           lng: -43.3938
        //         },
        //         {
        //           lat: -22.94665,
        //           lng: -43.39358
        //         },
        //         {
        //           lat: -22.94698,
        //           lng: -43.39327
        //         },
        //         {
        //           lat: -22.94721,
        //           lng: -43.39301
        //         },
        //         {
        //           lat: -22.94741,
        //           lng: -43.39269
        //         },
        //         {
        //           lat: -22.94809,
        //           lng: -43.39153
        //         },
        //         {
        //           lat: -22.94841,
        //           lng: -43.39102
        //         },
        //         {
        //           lat: -22.94866,
        //           lng: -43.39071
        //         },
        //         {
        //           lat: -22.94888,
        //           lng: -43.39049
        //         },
        //         {
        //           lat: -22.94901,
        //           lng: -43.3904
        //         },
        //         {
        //           lat: -22.94919,
        //           lng: -43.39031
        //         },
        //         {
        //           lat: -22.94947,
        //           lng: -43.39019
        //         },
        //         {
        //           lat: -22.94978,
        //           lng: -43.39011
        //         },
        //         {
        //           lat: -22.95094,
        //           lng: -43.38987
        //         },
        //         {
        //           lat: -22.9512,
        //           lng: -43.38985
        //         },
        //         {
        //           lat: -22.95149,
        //           lng: -43.38987
        //         },
        //         {
        //           lat: -22.95174,
        //           lng: -43.38994
        //         },
        //         {
        //           lat: -22.95196,
        //           lng: -43.39003
        //         },
        //         {
        //           lat: -22.95217,
        //           lng: -43.39015
        //         },
        //         {
        //           lat: -22.95238,
        //           lng: -43.39027
        //         },
        //         {
        //           lat: -22.95348,
        //           lng: -43.39102
        //         },
        //         {
        //           lat: -22.95444,
        //           lng: -43.39173
        //         },
        //         {
        //           lat: -22.9551,
        //           lng: -43.39232
        //         },
        //         {
        //           lat: -22.9557,
        //           lng: -43.39291
        //         },
        //         {
        //           lat: -22.95603,
        //           lng: -43.39315
        //         },
        //         {
        //           lat: -22.95624,
        //           lng: -43.39326
        //         },
        //         {
        //           lat: -22.95641,
        //           lng: -43.39335
        //         },
        //         {
        //           lat: -22.95669,
        //           lng: -43.39343
        //         },
        //         {
        //           lat: -22.95682,
        //           lng: -43.39346
        //         },
        //         {
        //           lat: -22.95703,
        //           lng: -43.39348
        //         },
        //         {
        //           lat: -22.95723,
        //           lng: -43.39349
        //         },
        //         {
        //           lat: -22.95742,
        //           lng: -43.39347
        //         },
        //         {
        //           lat: -22.95754,
        //           lng: -43.39345
        //         },
        //         {
        //           lat: -22.95767,
        //           lng: -43.39343
        //         },
        //         {
        //           lat: -22.9579,
        //           lng: -43.39336
        //         },
        //         {
        //           lat: -22.95806,
        //           lng: -43.3933
        //         },
        //         {
        //           lat: -22.95821,
        //           lng: -43.39322
        //         },
        //         {
        //           lat: -22.95854,
        //           lng: -43.393
        //         },
        //         {
        //           lat: -22.95977,
        //           lng: -43.39206
        //         },
        //         {
        //           lat: -22.95995,
        //           lng: -43.39198
        //         },
        //         {
        //           lat: -22.96016,
        //           lng: -43.39192
        //         },
        //         {
        //           lat: -22.96031,
        //           lng: -43.3919
        //         },
        //         {
        //           lat: -22.96047,
        //           lng: -43.39189
        //         },
        //         {
        //           lat: -22.96063,
        //           lng: -43.3919
        //         },
        //         {
        //           lat: -22.96082,
        //           lng: -43.39193
        //         },
        //         {
        //           lat: -22.96106,
        //           lng: -43.39202
        //         },
        //         {
        //           lat: -22.9613,
        //           lng: -43.39215
        //         },
        //         {
        //           lat: -22.9613,
        //           lng: -43.39215
        //         },
        //         {
        //           lat: -22.96145,
        //           lng: -43.39228
        //         },
        //         {
        //           lat: -22.9636,
        //           lng: -43.39432
        //         },
        //         {
        //           lat: -22.96363,
        //           lng: -43.39434
        //         },
        //         {
        //           lat: -22.96402,
        //           lng: -43.39469
        //         },
        //         {
        //           lat: -22.96423,
        //           lng: -43.39488
        //         },
        //         {
        //           lat: -22.96486,
        //           lng: -43.39552
        //         },
        //         {
        //           lat: -22.96588,
        //           lng: -43.39645
        //         },
        //         {
        //           lat: -22.96606,
        //           lng: -43.39661
        //         },
        //         {
        //           lat: -22.96619,
        //           lng: -43.39674
        //         },
        //         {
        //           lat: -22.96713,
        //           lng: -43.39764
        //         },
        //         {
        //           lat: -22.96718,
        //           lng: -43.39768
        //         },
        //         {
        //           lat: -22.96723,
        //           lng: -43.39773
        //         },
        //         {
        //           lat: -22.96895,
        //           lng: -43.39932
        //         },
        //         {
        //           lat: -22.9695,
        //           lng: -43.39982
        //         },
        //         {
        //           lat: -22.96986,
        //           lng: -43.40011
        //         },
        //         {
        //           lat: -22.97014,
        //           lng: -43.40036
        //         },
        //         {
        //           lat: -22.97014,
        //           lng: -43.40036
        //         },
        //         {
        //           lat: -22.97054,
        //           lng: -43.40087
        //         },
        //         {
        //           lat: -22.97071,
        //           lng: -43.40101
        //         },
        //         {
        //           lat: -22.97235,
        //           lng: -43.40255
        //         },
        //         {
        //           lat: -22.97244,
        //           lng: -43.40264
        //         },
        //         {
        //           lat: -22.97248,
        //           lng: -43.4027
        //         },
        //         {
        //           lat: -22.97255,
        //           lng: -43.40273
        //         },
        //         {
        //           lat: -22.97261,
        //           lng: -43.40274
        //         },
        //         {
        //           lat: -22.97268,
        //           lng: -43.40274
        //         },
        //         {
        //           lat: -22.97274,
        //           lng: -43.40271
        //         },
        //         {
        //           lat: -22.9728,
        //           lng: -43.40267
        //         },
        //         {
        //           lat: -22.97284,
        //           lng: -43.40261
        //         },
        //         {
        //           lat: -22.97287,
        //           lng: -43.40255
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.40248
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.40248
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.4024
        //         },
        //         {
        //           lat: -22.97289,
        //           lng: -43.40164
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.40116
        //         },
        //         {
        //           lat: -22.97288,
        //           lng: -43.40085
        //         },
        //         {
        //           lat: -22.97289,
        //           lng: -43.40079
        //         },
        //         {
        //           lat: -22.9729,
        //           lng: -43.40062
        //         },
        //         {
        //           lat: -22.97292,
        //           lng: -43.40045
        //         },
        //         {
        //           lat: -22.97299,
        //           lng: -43.40012
        //         },
        //         {
        //           lat: -22.97306,
        //           lng: -43.39988
        //         },
        //         {
        //           lat: -22.97321,
        //           lng: -43.39921
        //         },
        //         {
        //           lat: -22.97323,
        //           lng: -43.39905
        //         },
        //         {
        //           lat: -22.97325,
        //           lng: -43.39885
        //         },
        //         {
        //           lat: -22.97326,
        //           lng: -43.39861
        //         },
        //         {
        //           lat: -22.97326,
        //           lng: -43.39846
        //         },
        //         {
        //           lat: -22.97326,
        //           lng: -43.39833
        //         },
        //         {
        //           lat: -22.97326,
        //           lng: -43.39823
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.39411
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.39294
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.39289
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.39278
        //         },
        //         {
        //           lat: -22.97333,
        //           lng: -43.39196
        //         },
        //         {
        //           lat: -22.97336,
        //           lng: -43.38823
        //         },
        //         {
        //           lat: -22.97337,
        //           lng: -43.38801
        //         },
        //         {
        //           lat: -22.97336,
        //           lng: -43.38778
        //         },
        //         {
        //           lat: -22.97336,
        //           lng: -43.38766
        //         },
        //         {
        //           lat: -22.97333,
        //           lng: -43.3874
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.3864
        //         },
        //         {
        //           lat: -22.97329,
        //           lng: -43.38574
        //         },
        //         {
        //           lat: -22.97329,
        //           lng: -43.38558
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.38367
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.38362
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.38202
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.38197
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.38131
        //         },
        //         {
        //           lat: -22.97331,
        //           lng: -43.37968
        //         },
        //         {
        //           lat: -22.9733,
        //           lng: -43.37956
        //         },
        //         {
        //           lat: -22.97331,
        //           lng: -43.37915
        //         },
        //         {
        //           lat: -22.97331,
        //           lng: -43.37885
        //         },
        //         {
        //           lat: -22.97331,
        //           lng: -43.37882
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.37872
        //         },
        //         {
        //           lat: -22.97332,
        //           lng: -43.37848
        //         },
        //         {
        //           lat: -22.97336,
        //           lng: -43.37789
        //         },
        //         {
        //           lat: -22.97338,
        //           lng: -43.37752
        //         },
        //         {
        //           lat: -22.97339,
        //           lng: -43.3773
        //         },
        //         {
        //           lat: -22.9734,
        //           lng: -43.37718
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37665
        //         },
        //         {
        //           lat: -22.97342,
        //           lng: -43.37602
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37565
        //         },
        //         {
        //           lat: -22.9734,
        //           lng: -43.37371
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37169
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37152
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37134
        //         },
        //         {
        //           lat: -22.97341,
        //           lng: -43.37124
        //         },
        //         {
        //           lat: -22.97344,
        //           lng: -43.36988
        //         },
        //         {
        //           lat: -22.97344,
        //           lng: -43.36869
        //         },
        //         {
        //           lat: -22.97347,
        //           lng: -43.36632
        //         },
        //         {
        //           lat: -22.97347,
        //           lng: -43.36621
        //         },
        //         {
        //           lat: -22.97347,
        //           lng: -43.3661
        //         },
        //         {
        //           lat: -22.9734381786719,
        //           lng: -43.364823460578926
        //         },
        //         {
        //           lat: -22.97338878922277,
        //           lng: -43.36450159549714
        //         },
        //         {
        //           lat: -22.97338878922277,
        //           lng: -43.36415827274323
        //         },
        //         {
        //           lat: -22.97348756810297,
        //           lng: -43.36397588253022
        //         },
        //         {
        //           lat: -22.97360857213282,
        //           lng: -43.3638
        //         },
        //         {
        //           lat: -22.973759209651263,
        //           lng: -43.363710343837745
        //         },
        //         {
        //           lat: -22.973870335581832,
        //           lng: -43.363675475120544
        //         },
        //         {
        //           lat: -22.97398886980708,
        //           lng: -43.363659381866455
        //         },
        //         {
        //           lat: -22.974117281767096,
        //           lng: -43.363678157329566
        //         },
        //         {
        //           lat: -22.9742333463183,
        //           lng: -43.36373180150986
        //         },
        //         {
        //           lat: -22.974765132010546,
        //           lng: -43.36397675347377
        //         },
        //         {
        //           lat: -22.975106850256584,
        //           lng: -43.36420456460275
        //         },
        //         {
        //           lat: -22.97679168004786,
        //           lng: -43.364914655685425
        //         },
        //         {
        //           lat: -22.97837703243498,
        //           lng: -43.36560130119324
        //         },
        //         {
        //           lat: -22.978707929185077,
        //           lng: -43.3656656742096
        //         },
        //         {
        //           lat: -22.979312924879192,
        //           lng: -43.36566030979157
        //         },
        //         {
        //           lat: -22.980006814564575,
        //           lng: -43.36560000000001
        //         },
        //         {
        //           lat: -22.980727863415797,
        //           lng: -43.36560000000001
        //         },
        //         {
        //           lat: -22.981231607590193,
        //           lng: -43.36579442024231
        //         },
        //         {
        //           lat: -22.981656331611173,
        //           lng: -43.36579442024231
        //         },
        //         {
        //           lat: -22.983049021940193,
        //           lng: -43.365831971168525
        //         },
        //         {
        //           lat: -22.98491579709432,
        //           lng: -43.365853428840644
        //         },
        //         {
        //           lat: -22.98665,
        //           lng: -43.36594
        //         },
        //         {
        //           lat: -22.98665,
        //           lng: -43.36594
        //         },
        //         {
        //           lat: -22.98817,
        //           lng: -43.36597
        //         },
        //         {
        //           lat: -22.99203,
        //           lng: -43.36603
        //         },
        //         {
        //           lat: -22.996012205747157,
        //           lng: -43.36601972579957
        //         },
        //         {
        //           lat: -22.996397378456717,
        //           lng: -43.365944623947144
        //         },
        //         {
        //           lat: -22.99669860250231,
        //           lng: -43.36590707302094
        //         },
        //         {
        //           lat: -22.99692575461676,
        //           lng: -43.36601436138154
        //         },
        //         {
        //           lat: -22.99718253480763,
        //           lng: -43.36609482765198
        //         },
        //         {
        //           lat: -22.997370181561315,
        //           lng: -43.36621820926667
        //         },
        //         {
        //           lat: -22.99756276611626,
        //           lng: -43.3664220571518
        //         },
        //         {
        //           lat: -22.997809668989916,
        //           lng: -43.3667278289795
        //         },
        //         {
        //           lat: -22.998130642050562,
        //           lng: -43.367108702659614
        //         },
        //         {
        //           lat: -22.999207130743418,
        //           lng: -43.368310332298286
        //         },
        //         {
        //           lat: -22.99959723232488,
        //           lng: -43.36877703666688
        //         },
        //         {
        //           lat: -22.999789813702602,
        //           lng: -43.368889689445496
        //         },
        //         {
        //           lat: -23.00005646438738,
        //           lng: -43.36893796920777
        //         },
        //         {
        //           lat: -23.00027373492626,
        //           lng: -43.3689433336258
        //         },
        //         {
        //           lat: -23.0005058189792,
        //           lng: -43.368921875953674
        //         },
        //         {
        //           lat: -23.000644081629556,
        //           lng: -43.368814587593086
        //         },
        //         {
        //           lat: -23.000782344138287,
        //           lng: -43.36866974830628
        //         },
        //         {
        //           lat: -23.00100388008746,
        //           lng: -43.368436298649854
        //         },
        //         {
        //           lat: -23.001209475636742,
        //           lng: -43.36825400590897
        //         },
        //         {
        //           lat: -23.00140699263564,
        //           lng: -43.36805552244187
        //         },
        //         {
        //           lat: -23.001431682240177,
        //           lng: -43.3679160475731
        //         },
        //         {
        //           lat: -23.001424275359284,
        //           lng: -43.367696106433876
        //         },
        //         {
        //           lat: -23.001369958220366,
        //           lng: -43.364828824996955
        //         },
        //         {
        //           lat: -23.001360082374575,
        //           lng: -43.36427628993989
        //         },
        //         {
        //           lat: -23.00127119972996,
        //           lng: -43.36421191692352
        //         },
        //         {
        //           lat: -23.00116256530704,
        //           lng: -43.364195823669434
        //         },
        //         {
        //           lat: -23.001,
        //           lng: -43.3642
        //         },
        //         {
        //           lat: -23.00097075462114,
        //           lng: -43.36431698154611
        //         },
        //         {
        //           lat: -23.001004551444908,
        //           lng: -43.3661699295044
        //         },
        //         {
        //           lat: -23.00105393079669,
        //           lng: -43.3681333065033
        //         },
        //         {
        //           lat: -23.000895916807377,
        //           lng: -43.368186950683594
        //         },
        //         {
        //           lat: -23.000742840578823,
        //           lng: -43.368106484413154
        //         },
        //         {
        //           lat: -23.000713212901637,
        //           lng: -43.36764514446259
        //         },
        //         {
        //           lat: -23.000713212901637,
        //           lng: -43.367114067077644
        //         },
        //         {
        //           lat: -23.00089999999999,
        //           lng: -43.36698532104492
        //         },
        //         {
        //           lat: -23.000960110012855,
        //           lng: -43.366845846176155
        //         },
        //         {
        //           lat: -23.00089999999999,
        //           lng: -43.36673319339753
        //         },
        //         {
        //           lat: -23.00075271646978,
        //           lng: -43.366658091545105
        //         },
        //         {
        //           lat: -23.000540384655068,
        //           lng: -43.3667814731598
        //         },
        //         {
        //           lat: -23.000288548813895,
        //           lng: -43.36677879095078
        //         },
        //         {
        //           lat: -23.000204603429093,
        //           lng: -43.36669564247132
        //         },
        //         {
        //           lat: -23.000189789532246,
        //           lng: -43.36661249399186
        //         },
        //         {
        //           lat: -23.000219417324328,
        //           lng: -43.36544573307037
        //         },
        //         {
        //           lat: -23.000174975633758,
        //           lng: -43.36464643478394
        //         },
        //         {
        //           lat: -23.00011572002358,
        //           lng: -43.36412608623505
        //         },
        //         {
        //           lat: -23.00009103017832,
        //           lng: -43.36373984813691
        //         },
        //         {
        //           lat: -23.000095968147743,
        //           lng: -43.36350381374359
        //         },
        //         {
        //           lat: -23.000095968147743,
        //           lng: -43.36299419403076
        //         },
        //         {
        //           lat: -23.000086092208736,
        //           lng: -43.36261868476868
        //         },
        //         {
        //           lat: -23.00014,
        //           lng: -43.36126
        //         },
        //         {
        //           lat: -23.00013,
        //           lng: -43.3609
        //         },
        //         {
        //           lat: -23.00013,
        //           lng: -43.36034
        //         },
        //         {
        //           lat: -23.00013,
        //           lng: -43.35817
        //         },
        //         {
        //           lat: -23.00016,
        //           lng: -43.35545
        //         },
        //         {
        //           lat: -23.00016,
        //           lng: -43.35525
        //         },
        //         {
        //           lat: -23.00017,
        //           lng: -43.35486
        //         },
        //         {
        //           lat: -23.00017,
        //           lng: -43.35477
        //         },
        //         {
        //           lat: -23.00017,
        //           lng: -43.35461
        //         },
        //         {
        //           lat: -23.00019,
        //           lng: -43.35381
        //         },
        //         {
        //           lat: -23.00019,
        //           lng: -43.35362
        //         },
        //         {
        //           lat: -23.00019,
        //           lng: -43.35335
        //         },
        //         {
        //           lat: -23.00019,
        //           lng: -43.35329
        //         },
        //         {
        //           lat: -23.0002,
        //           lng: -43.35311
        //         },
        //         {
        //           lat: -23.00028,
        //           lng: -43.34903
        //         },
        //         {
        //           lat: -23.00029,
        //           lng: -43.3474
        //         },
        //         {
        //           lat: -23.00029,
        //           lng: -43.34734
        //         },
        //         {
        //           lat: -23.00029,
        //           lng: -43.34724
        //         },
        //         {
        //           lat: -23.00031,
        //           lng: -43.34666
        //         },
        //         {
        //           lat: -23.00037,
        //           lng: -43.34291
        //         },
        //         {
        //           lat: -23.00037,
        //           lng: -43.34274
        //         },
        //         {
        //           lat: -23.00037,
        //           lng: -43.3426
        //         },
        //         {
        //           lat: -23.00038,
        //           lng: -43.3415
        //         },
        //         {
        //           lat: -23.00038,
        //           lng: -43.34145
        //         },
        //         {
        //           lat: -23.00038,
        //           lng: -43.3413
        //         },
        //         {
        //           lat: -23.00043,
        //           lng: -43.33949
        //         },
        //         {
        //           lat: -23.00044,
        //           lng: -43.33933
        //         },
        //         {
        //           lat: -23.00045,
        //           lng: -43.33912
        //         },
        //         {
        //           lat: -23.00046,
        //           lng: -43.33895
        //         },
        //         {
        //           lat: -23.0006,
        //           lng: -43.33674
        //         },
        //         {
        //           lat: -23.0006,
        //           lng: -43.33668
        //         },
        //         {
        //           lat: -23.00061,
        //           lng: -43.33655
        //         },
        //         {
        //           lat: -23.00073,
        //           lng: -43.33463
        //         },
        //         {
        //           lat: -23.00074,
        //           lng: -43.33456
        //         },
        //         {
        //           lat: -23.00075,
        //           lng: -43.33441
        //         },
        //         {
        //           lat: -23.00089,
        //           lng: -43.33233
        //         },
        //         {
        //           lat: -23.00095,
        //           lng: -43.33127
        //         },
        //         {
        //           lat: -23.001,
        //           lng: -43.33086
        //         },
        //         {
        //           lat: -23.00116,
        //           lng: -43.32995
        //         },
        //         {
        //           lat: -23.00118,
        //           lng: -43.32983
        //         },
        //         {
        //           lat: -23.00122,
        //           lng: -43.32967
        //         },
        //         {
        //           lat: -23.00139,
        //           lng: -43.32917
        //         },
        //         {
        //           lat: -23.00158,
        //           lng: -43.32864
        //         },
        //         {
        //           lat: -23.0016,
        //           lng: -43.32859
        //         },
        //         {
        //           lat: -23.00169,
        //           lng: -43.32838
        //         },
        //         {
        //           lat: -23.00206,
        //           lng: -43.32745
        //         },
        //         {
        //           lat: -23.00287,
        //           lng: -43.32542
        //         },
        //         {
        //           lat: -23.00295,
        //           lng: -43.32523
        //         },
        //         {
        //           lat: -23.00306,
        //           lng: -43.32498
        //         },
        //         {
        //           lat: -23.00322,
        //           lng: -43.32458
        //         },
        //         {
        //           lat: -23.00327,
        //           lng: -43.32446
        //         },
        //         {
        //           lat: -23.0033,
        //           lng: -43.32439
        //         },
        //         {
        //           lat: -23.00344,
        //           lng: -43.324
        //         },
        //         {
        //           lat: -23.0035,
        //           lng: -43.32385
        //         },
        //         {
        //           lat: -23.00354,
        //           lng: -43.32376
        //         },
        //         {
        //           lat: -23.0037,
        //           lng: -43.32337
        //         },
        //         {
        //           lat: -23.00403,
        //           lng: -43.32258
        //         },
        //         {
        //           lat: -23.00405,
        //           lng: -43.32252
        //         },
        //         {
        //           lat: -23.00411,
        //           lng: -43.32239
        //         },
        //         {
        //           lat: -23.00429,
        //           lng: -43.32198
        //         },
        //         {
        //           lat: -23.00437,
        //           lng: -43.32182
        //         },
        //         {
        //           lat: -23.00447,
        //           lng: -43.32162
        //         },
        //         {
        //           lat: -23.00453,
        //           lng: -43.32147
        //         },
        //         {
        //           lat: -23.0046,
        //           lng: -43.32127
        //         },
        //         {
        //           lat: -23.00474,
        //           lng: -43.3209
        //         },
        //         {
        //           lat: -23.00554,
        //           lng: -43.31871
        //         },
        //         {
        //           lat: -23.00565,
        //           lng: -43.31847
        //         },
        //         {
        //           lat: -23.00565,
        //           lng: -43.31847
        //         },
        //         {
        //           lat: -23.005917708417062,
        //           lng: -43.317659497261054
        //         },
        //         {
        //           lat: -23.006115218525277,
        //           lng: -43.31720352172852
        //         },
        //         {
        //           lat: -23.006327541569178,
        //           lng: -43.31647396087647
        //         },
        //         {
        //           lat: -23.006436171834554,
        //           lng: -43.31598579883576
        //         },
        //         {
        //           lat: -23.006500362404815,
        //           lng: -43.31557273864747
        //         },
        //         {
        //           lat: -23.00651517560899,
        //           lng: -43.31488877534866
        //         },
        //         {
        //           lat: -23.00661146139653,
        //           lng: -43.314228951931
        //         },
        //         {
        //           lat: -23.00672749802357,
        //           lng: -43.3135610818863
        //         },
        //         {
        //           lat: -23.006801563903558,
        //           lng: -43.312627673149116
        //         }],
        //         latitude: "",
        //         longitude: "",
        //         way: 9432
        //       },
        //       {
        //         id: 3973,
        //         name: "Terminal Alvorada",
        //         clientId: 209,
        //         latitude: "-23.000942",
        //         longitude: "-43.36583300000001",
        //         polyLine: [],
        //         way: 9432
        //       },
        //       {
        //         id: 61,
        //         name: "12 - PINGO D'AGUA X ALVORADA ( EXPRESSO )",
        //         clientId: 209,
        //         polyLine: [
        //           {
        //             lat: -23.00103742067709,
        //             lng: -43.365840728836076
        //           },
        //           {
        //             lat: -23.00105397257643,
        //             lng: -43.366151258583045
        //           },
        //           {
        //             lat: -23.001090374181167,
        //             lng: -43.36740808316233
        //           },
        //           {
        //             lat: -23.001093761182194,
        //             lng: -43.368041859870004
        //           },
        //           {
        //             lat: -23.001026323606368,
        //             lng: -43.368208544519064
        //           },
        //           {
        //             lat: -23.000859280453952,
        //             lng: -43.36827579358942
        //           },
        //           {
        //             lat: -23.000785634688764,
        //             lng: -43.368186036509826
        //           },
        //           {
        //             lat: -23.000746342818367,
        //             lng: -43.368095560416805
        //           },
        //           {
        //             lat: -23.00073904174393,
        //             lng: -43.367607757882865
        //           },
        //           {
        //             lat: -23.00073045326052,
        //             lng: -43.36726193267333
        //           },
        //           {
        //             lat: -23.000773069500013,
        //             lng: -43.36709974890471
        //           },
        //           {
        //             lat: -23.000870915721478,
        //             lng: -43.36700524597529
        //           },
        //           {
        //             lat: -23.00095440437547,
        //             lng: -43.366901668121216
        //           },
        //           {
        //             lat: -23.000959114155076,
        //             lng: -43.36675868408764
        //           },
        //           {
        //             lat: -23.00088493098941,
        //             lng: -43.3666952386979
        //           },
        //           {
        //             lat: -23.000781177160324,
        //             lng: -43.366671562630074
        //           },
        //           {
        //             lat: -23.000593478108524,
        //             lng: -43.36677663259508
        //           },
        //           {
        //             lat: -23.000182361740162,
        //             lng: -43.36681728504186
        //           },
        //           {
        //             lat: -23.00016254725097,
        //             lng: -43.3678319986725
        //           },
        //           {
        //             lat: -23.000135263184163,
        //             lng: -43.36900580125808
        //           },
        //           {
        //             lat: -23.000072681738132,
        //             lng: -43.36957326389313
        //           },
        //           {
        //             lat: -23.000135038009454,
        //             lng: -43.370094721565295
        //           },
        //           {
        //             lat: -23.00012121640835,
        //             lng: -43.37074314617155
        //           },
        //           {
        //             lat: -23.00011196068494,
        //             lng: -43.37131818916322
        //           },
        //           {
        //             lat: -23.000038511366203,
        //             lng: -43.37201311904914
        //           },
        //           {
        //             lat: -23.000078263229966,
        //             lng: -43.373089941139256
        //           },
        //           {
        //             lat: -23.000100000000003,
        //             lng: -43.37353999999999
        //           },
        //           {
        //             lat: -23.00015,
        //             lng: -43.373760000000004
        //           },
        //           {
        //             lat: -23.00018,
        //             lng: -43.37581
        //           },
        //           {
        //             lat: -23.000230000000002,
        //             lng: -43.37829999999997
        //           },
        //           {
        //             lat: -23.000300000000003,
        //             lng: -43.38207
        //           },
        //           {
        //             lat: -23.00033,
        //             lng: -43.38548000000003
        //           },
        //           {
        //             lat: -23.00037,
        //             lng: -43.388270000000034
        //           },
        //           {
        //             lat: -23.00034,
        //             lng: -43.38940000000002
        //           },
        //           {
        //             lat: -23.000290000000003,
        //             lng: -43.391279999999995
        //           },
        //           {
        //             lat: -23.00024,
        //             lng: -43.39399000000003
        //           },
        //           {
        //             lat: -23.00015,
        //             lng: -43.396799999999985
        //           },
        //           {
        //             lat: -23.00005,
        //             lng: -43.40029000000004
        //           },
        //           {
        //             lat: -23.000000000000004,
        //             lng: -43.40129000000002
        //           },
        //           {
        //             lat: -22.99989,
        //             lng: -43.40350000000001
        //           },
        //           {
        //             lat: -22.999820000000003,
        //             lng: -43.405820000000006
        //           },
        //           {
        //             lat: -22.99977,
        //             lng: -43.40778999999998
        //           },
        //           {
        //             lat: -22.999750000000002,
        //             lng: -43.40848
        //           },
        //           {
        //             lat: -22.999760000000002,
        //             lng: -43.40960000000001
        //           },
        //           {
        //             lat: -22.999850000000002,
        //             lng: -43.41165999999998
        //           },
        //           {
        //             lat: -22.999950000000002,
        //             lng: -43.41278
        //           },
        //           {
        //             lat: -23.00007,
        //             lng: -43.41383000000002
        //           },
        //           {
        //             lat: -23.000300000000003,
        //             lng: -43.41534999999999
        //           },
        //           {
        //             lat: -23.00046,
        //             lng: -43.41623000000004
        //           },
        //           {
        //             lat: -23.00062,
        //             lng: -43.41696999999999
        //           },
        //           {
        //             lat: -23.000950000000003,
        //             lng: -43.41836999999998
        //           },
        //           {
        //             lat: -23.00128,
        //             lng: -43.41949999999997
        //           },
        //           {
        //             lat: -23.00159,
        //             lng: -43.420500000000004
        //           },
        //           {
        //             lat: -23.001980000000003,
        //             lng: -43.42168000000004
        //           },
        //           {
        //             lat: -23.0026,
        //             lng: -43.42357000000004
        //           },
        //           {
        //             lat: -23.00316,
        //             lng: -43.42523
        //           },
        //           {
        //             lat: -23.003490000000003,
        //             lng: -43.42626999999999
        //           },
        //           {
        //             lat: -23.004730000000002,
        //             lng: -43.43002999999999
        //           },
        //           {
        //             lat: -23.005070000000003,
        //             lng: -43.431100000000015
        //           },
        //           {
        //             lat: -23.005540000000003,
        //             lng: -43.43273999999997
        //           },
        //           {
        //             lat: -23.00656,
        //             lng: -43.43673000000001
        //           },
        //           {
        //             lat: -23.006880000000002,
        //             lng: -43.43798000000004
        //           },
        //           {
        //             lat: -23.0072,
        //             lng: -43.439269999999965
        //           },
        //           {
        //             lat: -23.007510000000003,
        //             lng: -43.44038999999998
        //           },
        //           {
        //             lat: -23.00777,
        //             lng: -43.44144
        //           },
        //           {
        //             lat: -23.008000000000003,
        //             lng: -43.44236000000001
        //           },
        //           {
        //             lat: -23.00852,
        //             lng: -43.4443
        //           },
        //           {
        //             lat: -23.009990000000002,
        //             lng: -43.45015999999998
        //           },
        //           {
        //             lat: -23.01094,
        //             lng: -43.45391000000001
        //           },
        //           {
        //             lat: -23.011200000000002,
        //             lng: -43.45487000000003
        //           },
        //           {
        //             lat: -23.011550000000003,
        //             lng: -43.45627000000002
        //           },
        //           {
        //             lat: -23.012120000000003,
        //             lng: -43.45848000000001
        //           },
        //           {
        //             lat: -23.012330000000002,
        //             lng: -43.45934
        //           },
        //           {
        //             lat: -23.01321,
        //             lng: -43.46266000000003
        //           },
        //           {
        //             lat: -23.013460000000002,
        //             lng: -43.46361999999999
        //           },
        //           {
        //             lat: -23.014830000000003,
        //             lng: -43.46899000000002
        //           },
        //           {
        //             lat: -23.01529,
        //             lng: -43.47077999999999
        //           },
        //           {
        //             lat: -23.01592,
        //             lng: -43.47320000000002
        //           },
        //           {
        //             lat: -23.016050000000003,
        //             lng: -43.47388000000001
        //           },
        //           {
        //             lat: -23.01619,
        //             lng: -43.47446000000002
        //           },
        //           {
        //             lat: -23.016330000000004,
        //             lng: -43.47501
        //           },
        //           {
        //             lat: -23.01706,
        //             lng: -43.47782000000001
        //           },
        //           {
        //             lat: -23.018,
        //             lng: -43.48154999999997
        //           },
        //           {
        //             lat: -23.018240000000002,
        //             lng: -43.482470000000035
        //           },
        //           {
        //             lat: -23.018510000000003,
        //             lng: -43.48356000000001
        //           },
        //           {
        //             lat: -23.01874,
        //             lng: -43.484339999999975
        //           },
        //           {
        //             lat: -23.019170000000003,
        //             lng: -43.48606000000001
        //           },
        //           {
        //             lat: -23.019350000000003,
        //             lng: -43.48688000000004
        //           },
        //           {
        //             lat: -23.020220000000002,
        //             lng: -43.49022000000002
        //           },
        //           {
        //             lat: -23.020410000000002,
        //             lng: -43.491250000000036
        //           },
        //           {
        //             lat: -23.020570000000003,
        //             lng: -43.4923
        //           },
        //           {
        //             lat: -23.02062,
        //             lng: -43.49263000000002
        //           },
        //           {
        //             lat: -23.020740000000004,
        //             lng: -43.49373000000003
        //           },
        //           {
        //             lat: -23.020850000000003,
        //             lng: -43.49505999999997
        //           },
        //           {
        //             lat: -23.020880000000002,
        //             lng: -43.49577999999997
        //           },
        //           {
        //             lat: -23.020850000000003,
        //             lng: -43.49651
        //           },
        //           {
        //             lat: -23.020780000000002,
        //             lng: -43.49739999999997
        //           },
        //           {
        //             lat: -23.0207,
        //             lng: -43.498440000000016
        //           },
        //           {
        //             lat: -23.020650000000003,
        //             lng: -43.49892999999997
        //           },
        //           {
        //             lat: -23.020580000000002,
        //             lng: -43.49959000000001
        //           },
        //           {
        //             lat: -23.02044,
        //             lng: -43.500350000000026
        //           },
        //           {
        //             lat: -23.020290000000003,
        //             lng: -43.50110000000001
        //           },
        //           {
        //             lat: -23.02014,
        //             lng: -43.50171999999998
        //           },
        //           {
        //             lat: -23.01959,
        //             lng: -43.50371999999999
        //           },
        //           {
        //             lat: -23.019440000000003,
        //             lng: -43.50414000000001
        //           },
        //           {
        //             lat: -23.01923,
        //             lng: -43.504610000000014
        //           },
        //           {
        //             lat: -23.01912,
        //             lng: -43.50490000000002
        //           },
        //           {
        //             lat: -23.018900000000002,
        //             lng: -43.50544000000002
        //           },
        //           {
        //             lat: -23.018720000000002,
        //             lng: -43.50612000000001
        //           },
        //           {
        //             lat: -23.01863,
        //             lng: -43.50661000000002
        //           },
        //           {
        //             lat: -23.018580000000004,
        //             lng: -43.50707
        //           },
        //           {
        //             lat: -23.018430000000002,
        //             lng: -43.507709999999975
        //           },
        //           {
        //             lat: -23.018,
        //             lng: -43.509379999999965
        //           },
        //           {
        //             lat: -23.017680000000002,
        //             lng: -43.510459999999966
        //           },
        //           {
        //             lat: -23.017590000000002,
        //             lng: -43.51084000000003
        //           },
        //           {
        //             lat: -23.01697,
        //             lng: -43.51312999999999
        //           },
        //           {
        //             lat: -23.01677,
        //             lng: -43.51387
        //           },
        //           {
        //             lat: -23.016180000000002,
        //             lng: -43.51597000000004
        //           },
        //           {
        //             lat: -23.01592,
        //             lng: -43.51714000000004
        //           },
        //           {
        //             lat: -23.015530000000002,
        //             lng: -43.51974999999999
        //           },
        //           {
        //             lat: -23.015390000000004,
        //             lng: -43.52098000000001
        //           },
        //           {
        //             lat: -23.01537,
        //             lng: -43.52168
        //           },
        //           {
        //             lat: -23.015410000000003,
        //             lng: -43.52217000000002
        //           },
        //           {
        //             lat: -23.01536,
        //             lng: -43.52251000000001
        //           },
        //           {
        //             lat: -23.01526,
        //             lng: -43.52307000000002
        //           },
        //           {
        //             lat: -23.015120000000003,
        //             lng: -43.523619999999994
        //           },
        //           {
        //             lat: -23.014750000000003,
        //             lng: -43.52469000000002
        //           },
        //           {
        //             lat: -23.014280000000003,
        //             lng: -43.52591000000001
        //           },
        //           {
        //             lat: -23.013700000000004,
        //             lng: -43.52724999999998
        //           },
        //           {
        //             lat: -23.01312,
        //             lng: -43.52852999999999
        //           },
        //           {
        //             lat: -23.011760000000002,
        //             lng: -43.531079999999974
        //           },
        //           {
        //             lat: -23.011370000000003,
        //             lng: -43.531920000000014
        //           },
        //           {
        //             lat: -23.011170000000003,
        //             lng: -43.53251999999998
        //           },
        //           {
        //             lat: -23.011080000000003,
        //             lng: -43.53287
        //           },
        //           {
        //             lat: -23.01103,
        //             lng: -43.533199999999965
        //           },
        //           {
        //             lat: -23.011000000000003,
        //             lng: -43.53424000000001
        //           },
        //           {
        //             lat: -23.010980000000004,
        //             lng: -43.53505000000001
        //           },
        //           {
        //             lat: -23.010910000000003,
        //             lng: -43.53561000000002
        //           },
        //           {
        //             lat: -23.01032,
        //             lng: -43.537739999999985
        //           },
        //           {
        //             lat: -23.0101,
        //             lng: -43.538520000000005
        //           },
        //           {
        //             lat: -23.009950000000003,
        //             lng: -43.53886
        //           },
        //           {
        //             lat: -23.009790000000002,
        //             lng: -43.53913
        //           },
        //           {
        //             lat: -23.00917,
        //             lng: -43.54009000000002
        //           },
        //           {
        //             lat: -23.00859,
        //             lng: -43.540970000000016
        //           },
        //           {
        //             lat: -23.00832,
        //             lng: -43.54142999999999
        //           },
        //           {
        //             lat: -23.008070000000004,
        //             lng: -43.54191000000003
        //           },
        //           {
        //             lat: -23.00785,
        //             lng: -43.542460000000005
        //           },
        //           {
        //             lat: -23.00776,
        //             lng: -43.54269999999997
        //           },
        //           {
        //             lat: -23.007600000000004,
        //             lng: -43.54329999999999
        //           },
        //           {
        //             lat: -23.0075,
        //             lng: -43.543859999999995
        //           },
        //           {
        //             lat: -23.007450000000002,
        //             lng: -43.54455999999999
        //           },
        //           {
        //             lat: -23.007460000000002,
        //             lng: -43.545600000000036
        //           },
        //           {
        //             lat: -23.0075,
        //             lng: -43.54784000000001
        //           },
        //           {
        //             lat: -23.00748,
        //             lng: -43.54845999999998
        //           },
        //           {
        //             lat: -23.007440000000003,
        //             lng: -43.54872
        //           },
        //           {
        //             lat: -23.007230000000003,
        //             lng: -43.54961000000003
        //           },
        //           {
        //             lat: -23.006990000000002,
        //             lng: -43.55063999999999
        //           },
        //           {
        //             lat: -23.00675,
        //             lng: -43.55165999999997
        //           },
        //           {
        //             lat: -23.00569,
        //             lng: -43.556129999999996
        //           },
        //           {
        //             lat: -23.005560000000003,
        //             lng: -43.556569999999965
        //           },
        //           {
        //             lat: -23.00532,
        //             lng: -43.55749000000003
        //           },
        //           {
        //             lat: -23.005080000000003,
        //             lng: -43.55848000000003
        //           },
        //           {
        //             lat: -23.00468,
        //             lng: -43.56043999999997
        //           },
        //           {
        //             lat: -23.004510000000003,
        //             lng: -43.561199999999985
        //           },
        //           {
        //             lat: -23.00392,
        //             lng: -43.56364000000002
        //           },
        //           {
        //             lat: -23.003590000000003,
        //             lng: -43.56515000000002
        //           },
        //           {
        //             lat: -23.00007,
        //             lng: -43.58006999999998
        //           },
        //           {
        //             lat: -22.999840000000003,
        //             lng: -43.58089000000001
        //           },
        //           {
        //             lat: -22.9995,
        //             lng: -43.58222000000001
        //           },
        //           {
        //             lat: -22.999190000000002,
        //             lng: -43.58352000000002
        //           },
        //           {
        //             lat: -22.99895,
        //             lng: -43.584749999999985
        //           },
        //           {
        //             lat: -22.998440000000002,
        //             lng: -43.586959999999976
        //           },
        //           {
        //             lat: -22.997400000000003,
        //             lng: -43.591380000000015
        //           },
        //           {
        //             lat: -22.997280000000003,
        //             lng: -43.59183999999999
        //           },
        //           {
        //             lat: -22.997110000000003,
        //             lng: -43.59228999999999
        //           },
        //           {
        //             lat: -22.9969,
        //             lng: -43.592719999999986
        //           },
        //           {
        //             lat: -22.996550000000003,
        //             lng: -43.59327000000002
        //           },
        //           {
        //             lat: -22.99624,
        //             lng: -43.59366
        //           },
        //           {
        //             lat: -22.99555,
        //             lng: -43.59441000000004
        //           },
        //           {
        //             lat: -22.995160000000002,
        //             lng: -43.59491000000003
        //           },
        //           {
        //             lat: -22.99462,
        //             lng: -43.59564999999998
        //           },
        //           {
        //             lat: -22.994410000000002,
        //             lng: -43.59590000000003
        //           },
        //           {
        //             lat: -22.99367,
        //             lng: -43.596749999999986
        //           },
        //           {
        //             lat: -22.992890000000003,
        //             lng: -43.59770000000003
        //           },
        //           {
        //             lat: -22.991750000000003,
        //             lng: -43.59906000000001
        //           },
        //           {
        //             lat: -22.991650000000003,
        //             lng: -43.59915999999998
        //           },
        //           {
        //             lat: -22.99151,
        //             lng: -43.599339999999984
        //           },
        //           {
        //             lat: -22.991270000000004,
        //             lng: -43.59973000000002
        //           },
        //           {
        //             lat: -22.99067,
        //             lng: -43.600810000000024
        //           },
        //           {
        //             lat: -22.990370000000002,
        //             lng: -43.60122999999999
        //           },
        //           {
        //             lat: -22.989900000000002,
        //             lng: -43.60178000000002
        //           },
        //           {
        //             lat: -22.989020000000004,
        //             lng: -43.6028
        //           },
        //           {
        //             lat: -22.988750000000003,
        //             lng: -43.60309000000001
        //           },
        //           {
        //             lat: -22.98797,
        //             lng: -43.60401000000002
        //           },
        //           {
        //             lat: -22.98742,
        //             lng: -43.60464000000002
        //           },
        //           {
        //             lat: -22.98702,
        //             lng: -43.605050000000006
        //           },
        //           {
        //             lat: -22.986210000000003,
        //             lng: -43.605999999999995
        //           },
        //           {
        //             lat: -22.984750000000002,
        //             lng: -43.60768999999999
        //           },
        //           {
        //             lat: -22.98402,
        //             lng: -43.60849000000002
        //           },
        //           {
        //             lat: -22.983690000000003,
        //             lng: -43.608780000000024
        //           },
        //           {
        //             lat: -22.983300000000003,
        //             lng: -43.609069999999974
        //           },
        //           {
        //             lat: -22.982850000000003,
        //             lng: -43.609339999999975
        //           },
        //           {
        //             lat: -22.982100000000003,
        //             lng: -43.60966000000002
        //           },
        //           {
        //             lat: -22.97491,
        //             lng: -43.61235999999997
        //           },
        //           {
        //             lat: -22.974290000000003,
        //             lng: -43.61259000000001
        //           },
        //           {
        //             lat: -22.97359,
        //             lng: -43.61286999999999
        //           },
        //           {
        //             lat: -22.97324,
        //             lng: -43.61306000000002
        //           },
        //           {
        //             lat: -22.97277,
        //             lng: -43.61338999999998
        //           },
        //           {
        //             lat: -22.972360000000002,
        //             lng: -43.61374999999998
        //           },
        //           {
        //             lat: -22.971980000000002,
        //             lng: -43.61414000000002
        //           },
        //           {
        //             lat: -22.971670000000003,
        //             lng: -43.61448999999999
        //           },
        //           {
        //             lat: -22.97154,
        //             lng: -43.61469999999997
        //           },
        //           {
        //             lat: -22.970740000000003,
        //             lng: -43.61554000000001
        //           },
        //           {
        //             lat: -22.9705,
        //             lng: -43.615800000000036
        //           },
        //           {
        //             lat: -22.970090000000003,
        //             lng: -43.616250000000036
        //           },
        //           {
        //             lat: -22.96984,
        //             lng: -43.61644000000001
        //           },
        //           {
        //             lat: -22.96965,
        //             lng: -43.61664999999999
        //           },
        //           {
        //             lat: -22.96931,
        //             lng: -43.617020000000025
        //           },
        //           {
        //             lat: -22.969040000000003,
        //             lng: -43.61736000000002
        //           },
        //           {
        //             lat: -22.968850000000003,
        //             lng: -43.61766
        //           },
        //           {
        //             lat: -22.96863,
        //             lng: -43.61808000000002
        //           },
        //           {
        //             lat: -22.968470000000003,
        //             lng: -43.61847999999998
        //           },
        //           {
        //             lat: -22.968380000000003,
        //             lng: -43.61878999999999
        //           },
        //           {
        //             lat: -22.968290000000003,
        //             lng: -43.619249999999965
        //           },
        //           {
        //             lat: -22.96824,
        //             lng: -43.619609999999966
        //           },
        //           {
        //             lat: -22.968220000000002,
        //             lng: -43.62011000000001
        //           },
        //           {
        //             lat: -22.96826,
        //             lng: -43.62068999999997
        //           },
        //           {
        //             lat: -22.968390000000003,
        //             lng: -43.62143000000003
        //           },
        //           {
        //             lat: -22.96863,
        //             lng: -43.622630000000015
        //           },
        //           {
        //             lat: -22.968780000000002,
        //             lng: -43.62329
        //           },
        //           {
        //             lat: -22.969070000000002,
        //             lng: -43.62458000000004
        //           },
        //           {
        //             lat: -22.969630000000002,
        //             lng: -43.62732
        //           },
        //           {
        //             lat: -22.96983,
        //             lng: -43.62822
        //           },
        //           {
        //             lat: -22.970000000000002,
        //             lng: -43.62907999999999
        //           },
        //           {
        //             lat: -22.970090000000003,
        //             lng: -43.62977999999998
        //           },
        //           {
        //             lat: -22.970100000000002,
        //             lng: -43.630210000000034
        //           },
        //           {
        //             lat: -22.97005,
        //             lng: -43.630840000000035
        //           },
        //           {
        //             lat: -22.969970000000004,
        //             lng: -43.631280000000004
        //           },
        //           {
        //             lat: -22.96965,
        //             lng: -43.63249999999999
        //           },
        //           {
        //             lat: -22.968300000000003,
        //             lng: -43.63713999999999
        //           },
        //           {
        //             lat: -22.967750000000002,
        //             lng: -43.63905999999997
        //           },
        //           {
        //             lat: -22.96721,
        //             lng: -43.64085
        //           },
        //           {
        //             lat: -22.965850000000003,
        //             lng: -43.64570000000003
        //           },
        //           {
        //             lat: -22.965670000000003,
        //             lng: -43.646150000000034
        //           },
        //           {
        //             lat: -22.96542,
        //             lng: -43.64661000000001
        //           },
        //           {
        //             lat: -22.96527,
        //             lng: -43.646799999999985
        //           },
        //           {
        //             lat: -22.965100000000003,
        //             lng: -43.646960000000036
        //           },
        //           {
        //             lat: -22.964840000000002,
        //             lng: -43.64719000000002
        //           }
        //         ],
        //         latitude: "",
        //         longitude: "",
        //         way: 9432
        //       },
        //       {
        //         id: 3968,
        //         name: "Estação Mato Alto",
        //         clientId: 209,
        //         latitude: "-22.993807",
        //         longitude: "-43.596798000000035",
        //         polyLine: [],
        //         way: 9432
        //       }
        //     ],
        //     end: {
        //       id: 40,
        //       name: "Caminhada",
        //       clientId: 209,
        //       walk: "Cerca de 9 min, 650 m"
        //     },
        //     journeyTimeText: "1h 15min"
        //   }
        // ]

        yield put(loadItinerariesSuccess(dataItineraries))
      }

    } catch (error) {
      //yield put(loadFailure());
      yield console.log("error loadItineraries == " + error)
      yield put(loadItinerariesFailure([]))
    }
  }
}


export function* loadPlaces(action: any) {
  try {

    var textoConsulta: string = action.payload.textoConsulta.replace(" ", "%20")

    var clienteId: string = action.payload.clienteId
    
    const response = yield call(placesClient(clienteId).get, textoConsulta);

    //yield console.log("clienteId == " + clienteId + " "+responseTeste.status)

    //const response = yield call(places.get, textoConsulta);

    if (response.status === 200) {

      const usersJson: any[] = response.data.features

      const dataJson = usersJson.map(json => ({
        nome: json.properties.name,
        descricao: " - " + json.properties.city + " - " + json.properties.state + " - " + json.properties.postcode + " - " + json.properties.country,
        latitude: json.geometry.coordinates[1],
        longitude: json.geometry.coordinates[0],
      }) as LocalFromPlace)

      /*       yield console.log("data == " + JSON.stringify(usersJson)) */

      yield put(loadPlacesSuccess(dataJson, action.payload.source));
    }
  } catch (error) {
    yield console.log("error loadPlaces == " + error)

    yield put(loadPlacesFailure([], action.payload.source));
  }
}
