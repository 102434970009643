let language: any = null;
let token: any = null;

export const initGetLocalStorage = () => {
    token = localStorage.getItem('token');
    language = localStorage.getItem('language') || "";//en-us es-es pt-br
}

export const getToken = () => {
    return token;
}

export const getLanguage = () => {
    return language;
}

