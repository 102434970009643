import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Itinerary, LocalFromPlace, SourcesTypes } from '../../store/ducks/itineraries/types';
import * as ItinerariesActions from '../../store/ducks/itineraries/actions';
import { ApplicationState } from '../../store';

//import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
//import { useLocation } from 'react-router-dom'

import './styles.css';
import '../../index.css'


import ItineraryList from '../../components/ItineraryList/';

import PlacesList from '../../components/PlacesList/';

import Logo from "../../assets/logo-sonda.png"
import Rotas from "../../assets/icons/icone-trocar.svg"
import IconeLocaliza from "../../../src/assets/icons/icone-localizacao_destino.png"
import bgImage from "../../assets/bg-map-png.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface StateProps { // Propriedades que vem do mapStateToProps
  localOrigem: LocalFromPlace,
  localDestino: LocalFromPlace,
  listaPlaces: LocalFromPlace[],
  sourceType: SourcesTypes,
  itineraries: Itinerary[],
  showProgress: boolean
}

interface DispatchProps { // Propriedades que vem do mapDispatchToProps
  loadLocationsFromPlaces(textoConsulta: string, source: SourcesTypes, clienteId: string): void,
  loadClienteId(clienteId: string): void,
  reverseItinerarySearch(origin: LocalFromPlace, destiny: LocalFromPlace, clienteId: string): void,
  clearSearch(origin: LocalFromPlace, destiny: LocalFromPlace): void
}

interface OwnProps { // Propriedades que vem do Componente Pai
  //testar receber o intl aqui
  clienteId: string

}

type Props = StateProps & DispatchProps & OwnProps
class Main extends Component<Props> {

  state = {
    tempOrigem: this.props.localOrigem ? this.props.localOrigem.nome : "",
    tempDestino: this.props.localDestino ? this.props.localDestino.nome : ""
  }

  componentDidMount() {

    //console.log("cliente id == " + this.props.clienteId)

    const { loadClienteId } = this.props;

    loadClienteId(this.props.clienteId)
  }

  handleCleanSearch = (source: SourcesTypes) => {

    const { clearSearch } = this.props;

    var temp = {
      nome: "",
      descricao: "",
      latitude: "",
      longitude: ""
    }

    switch (source) {
      case SourcesTypes.SOURCE_ORIGEM:
        this.setState({ tempOrigem: "" });
        clearSearch(temp, this.props.localDestino)
        break;
      case SourcesTypes.SOURCE_DESTINO:
        this.setState({ tempDestino: "" });
        clearSearch(this.props.localOrigem, temp)
        break;
      default:
        this.setState({});
    }

  }

  handleChange = (e: any, source: SourcesTypes) => {
    //console.log(e.target.value)
    switch (source) {
      case SourcesTypes.SOURCE_ORIGEM:
        this.setState({ tempOrigem: e.target.value });
        break;
      case SourcesTypes.SOURCE_DESTINO:
        this.setState({ tempDestino: e.target.value });
        break;
      default:
        this.setState({});
    }

    const { loadLocationsFromPlaces } = this.props;

    if (e.target.value.length > 3)
      loadLocationsFromPlaces(e.target.value, source, this.props.clienteId)

  };

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {

    if ((prevProps.localOrigem !== this.props.localOrigem) && this.props.localOrigem.nome !== prevState.tempOrigem)
      this.setState({ tempOrigem: this.props.localOrigem.nome });

    if ((prevProps.localDestino !== this.props.localDestino) && this.props.localDestino.nome !== prevState.tempDestino)
      this.setState({ tempDestino: this.props.localDestino.nome });

  }

  render() {

    const { tempOrigem, tempDestino } = this.state;

    return (
      <div>
        <div className="body-image">
          <img className="bgImg" src={bgImage} alt=" " />
        </div>
        <div className="container-main ">
          <div className="container-header">
            <div className="topo-header">
              <img className="logo-img" src={Logo} alt=" " />
              <p className="header-title">
                {/* intl.formatMessage({id: "main.titulo"}) */}
                <FormattedMessage id="main.titulo" />
              </p>
            </div>

            <div className="header-searchBoxContainer">



              <div className="header-searchBox">
                <img alt='{intl.formatMessage({ id: "main.alt.imagenavigate" })}' className="header-imageNavigate" src={IconeLocaliza} />

                <div className="tabela">
                  <div className="container-searchInputTextOrigin">
                    <div className="text-searchInputTextOrigin"><FormattedMessage id="main.placeholder.origem" /></div>
                    <input
                      className="header-searchInputTextOrigin"
                      value={tempOrigem}
                      onChange={text =>
                        this.handleChange(text, SourcesTypes.SOURCE_ORIGEM)
                      } />
                    <span className="limparPartida" onClick={t => this.handleCleanSearch(SourcesTypes.SOURCE_ORIGEM)}><FontAwesomeIcon icon={faPlus} /></span>
                  </div>

                  {/* <div className="header-separator" /> */}

                  <div className="container-searchInputTextDestiny">
                    <div className="text-searchInputTextDestiny"><FormattedMessage id="main.placeholder.destino" /></div>

                    <input
                      className="header-searchInputTextDestiny"
                      value={tempDestino}
                      onChange={text =>
                        this.handleChange(text, SourcesTypes.SOURCE_DESTINO)
                      } />
                    <span className="limparDestino" onClick={t => this.handleCleanSearch(SourcesTypes.SOURCE_DESTINO)}><FontAwesomeIcon icon={faPlus} /></span>
                  </div>
                </div>

                <img className="rotas-img" src={Rotas} alt=" " onClick={this.onReverseItinerarySearch} />
              </div>

            </div>
            {/* <Link to={`/maps/1`}><FormattedMessage id="main.link" /></Link> */}
          </div>
          <div className="container-footer">
            <div className="footer-lines">{this.renderContent()}</div>
            <div className="footer-lines-places">{this.renderContentPlaces()}</div>
          </div>
        </div>
      </div>
    );
  }

  onReverseItinerarySearch = () => {
    this.props.reverseItinerarySearch(this.props.localOrigem, this.props.localDestino, this.props.clienteId);
  }

  private renderContentPlaces() {
    const { listaPlaces } = this.props;
    if (listaPlaces.length > 0)
      return <PlacesList />;
    else
      return null;

  }

  private renderContent() {
    if (this.props.showProgress) {
      return (
        <div className="itineraries-loading">
          <FormattedMessage id="itineraries.carregando" />
        </div>
      );
    }
    const { itineraries, listaPlaces } = this.props;

    if (itineraries.length > 0)
      return <ItineraryList />;
    else if (itineraries.length < 1 && listaPlaces.length < 1)
      return <div className="itineraries-empty">
        <div> <FormattedMessage id="itineraries.vazio" /></div>

        <div><FormattedMessage id="itineraries.vazio.rotas" /></div>

      </div>;
    else
      return null
  }
}
const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
  itineraries: state.itineraries.itineraries,
  localOrigem: state.itineraries.localOrigem,
  localDestino: state.itineraries.localDestino,
  listaPlaces: state.itineraries.listaPlaces,
  sourceType: state.itineraries.sourceType,
  clienteId: ownProps.match.params.clienteId,
  showProgress: state.itineraries.showProgress
});

const mapDispatchToProps = (dispatch: Dispatch) => (
  bindActionCreators(ItinerariesActions, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Main);