import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import * as ItinerariesActions from '../../store/ducks/itineraries/actions';
import { Itinerary/* , ItineraryDetail */ } from '../../store/ducks/itineraries/types';
import { ApplicationState } from '../../store';
import { LocalFromPlace, SourcesTypes } from '../../store/ducks/itineraries/types';

import './styles.css'
import 'leaflet/dist/leaflet.css';

import bgImage from "../../assets/bg-map-png.png"
/*import mapIcon from "../../assets/icons/icone-pin.svg"*/

/*import icon from 'leaflet/dist/images/marker-icon.png';*/
import icon from '../../assets/icons/icone-pin.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconVermelho from '../../assets/icons/icone-origemDestino-Map.svg';
import { FormattedMessage } from "react-intl";

let DefaultIcon = Leaflet.icon({
    ...Leaflet.Icon.Default.prototype.options,
    iconUrl: icon,
    iconRetinaUrl: iconRetina,
    shadowUrl: iconShadow
});
Leaflet.Marker.prototype.options.icon = DefaultIcon;

let IconRed = Leaflet.icon({
    ...Leaflet.Icon.Default.prototype.options,
    iconUrl: iconVermelho,
    iconRetinaUrl: iconRetina,
    shadowUrl: iconShadow
});


interface StateProps {
    listaPlaces: LocalFromPlace[],
    sourceType: SourcesTypes,
    localOrigem: LocalFromPlace,
    localDestino: LocalFromPlace,
    clienteId: string,
    itineraries: Itinerary[],
    currentItinerary: Itinerary,
    mapPolylines: [number, number][],
    mapPolylinesColor: number
}

interface DispatchProps {
    changeFilter(origin: LocalFromPlace, destiny: LocalFromPlace, clienteId: string): void
}

interface OwnProps {

}

type Props = StateProps & DispatchProps & OwnProps

class ItineraryMap extends Component<Props> {

    render() {
        const { localOrigem } = this.props

        return (

            <div className="itinerariosMap">
                <div className="body-image">
                    <img className="bgImg" src={bgImage} alt=" "/>
                </div>
                <MapContainer center={localOrigem.latitude ? [Number(localOrigem.latitude), Number(localOrigem.longitude)] : [-22.973517, -43.368696]} zoom={12} scrollWheelZoom={false}
                    style={{ height: '385px', borderRadius: '22px' }}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {this.renderMarkers()}
                    {this.renderPolylines()}
                </MapContainer>
            </div >
        )
    }

    private renderMarkers() {

        const { currentItinerary, localDestino, localOrigem } = this.props

        const views = currentItinerary.itinerary.map((it, index) => {
            if (it.latitude != null)
                return (
                    <Marker
                        key={index}
                        position={[Number(it.latitude),
                        Number(it.longitude)]}
                    >
                        <Popup>
                            {it.name}
                        </Popup>

                    </Marker>)
            else
                return null;

        });

        if (localDestino.nome) {
            views.push(
                <Marker icon={IconRed}
                    key={localDestino.latitude}
                    position={[Number(localDestino.latitude), Number(localDestino.longitude)]}>
                    <Popup>
                        <FormattedMessage id="map.destino" />
                    </Popup>
                </Marker>
            )
        }

        if (localOrigem.nome) {
            views.push(
                <Marker icon={IconRed}
                    key={localOrigem.latitude}
                    position={[Number(localOrigem.latitude), Number(localOrigem.longitude)]}>
                    <Popup>
                        <FormattedMessage id="map.origem" />
                    </Popup>
                </Marker>
            )
        }

        return views;
    }

    private renderPolylines() {

        const { mapPolylines, mapPolylinesColor } = this.props
        const blueOptions = { color: 'blue' }
        const greenOptions = { color: 'green' }

        if (mapPolylines) {

            const option = mapPolylinesColor === 0 ? blueOptions : greenOptions;

            return (<Polyline pathOptions={option} positions={mapPolylines} />)
        } else {
            /* const polyline: [number, number][] = [
                [-22.972134, -43.388455],
                [-22.951555, -43.357385],
                [-22.955191, -43.376955],
                [-22.936222, -43.376955]
            ] */

            const polylineEmpty: [number, number][] = []
            return (<Polyline pathOptions={blueOptions} positions={polylineEmpty} />)
        }

    }
}

const mapStateToProps = (state: ApplicationState) => ({
    listaPlaces: state.itineraries.listaPlaces,
    sourceType: state.itineraries.sourceType,
    localOrigem: state.itineraries.localOrigem,
    localDestino: state.itineraries.localDestino,
    clienteId: state.itineraries.clienteId,
    itineraries: state.itineraries.itineraries,
    currentItinerary: state.itineraries.currentItinerary,
    mapPolylines: state.itineraries.mapPolylines,
    mapPolylinesColor: state.itineraries.mapPolylinesColor
});

const mapDispatchToProps = (dispatch: Dispatch) => (
    bindActionCreators(ItinerariesActions, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryMap);

