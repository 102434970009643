/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import { ItinerariesTypes, LocalFromPlace, SourcesTypes, Itinerary } from './types';

export const loadLocationsFromPlaces = (textoConsulta: string, source: SourcesTypes, clienteId: string) => action(ItinerariesTypes.LOAD_PLACES, { textoConsulta, source, clienteId });

export const loadPlacesSuccess = (data: LocalFromPlace[], source: SourcesTypes) => action(ItinerariesTypes.LOAD_PLACES_SUCCESS, { data, source });

export const loadPlacesFailure = (data: LocalFromPlace[], source: SourcesTypes) => action(ItinerariesTypes.LOAD_PLACES_FAILURE, { data, source });

export const changeFilter = (origin: LocalFromPlace, destiny: LocalFromPlace, clienteId: string) => action(ItinerariesTypes.CHANGE_ORIGIN_DESTINY, { origin, destiny, clienteId });

export const loadItinerariesSuccess = (itineraries: Itinerary[]) => action(ItinerariesTypes.LOAD_ITINERARIES_SUCCESS, { itineraries })

export const loadItinerariesFailure = (itineraries: Itinerary[]) => action(ItinerariesTypes.LOAD_ITINERARIES_FAILURE, { itineraries })

export const changeCurrentItinerary = (itinerary: Itinerary) => action(ItinerariesTypes.CHANGE_CURRENT_ITINERARY, { itinerary })

export const loadClienteId = (clienteId: string) => action(ItinerariesTypes.CHANGE_CURRENT_CLIENTEID, { clienteId })

export const changeMapPolyline = (mapPolylines: [number, number][] | undefined, mapPolylinesColor: number) => action(ItinerariesTypes.SHOW_MAP_POLYLINES, { mapPolylines, mapPolylinesColor })

export const clearSearch = (origin: LocalFromPlace, destiny: LocalFromPlace) => action(ItinerariesTypes.CLEAR_SEARCH, { origin, destiny })

export const clearItinerarySearch = () => action(ItinerariesTypes.CLEAR_ITINERARY_SEARCH, {})

export const reverseItinerarySearch = (origin: LocalFromPlace, destiny: LocalFromPlace, clienteId: string) => action(ItinerariesTypes.REVERSE_ITINERARY_SEARCH, { origin, destiny, clienteId })




